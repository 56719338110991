import { handshake } from "./requests";
import { useQuery } from "@tanstack/react-query";
import { GET_ROUTES } from "./routes";

export const useHandshake = (token: string) => {
  return useQuery({
    queryKey: [GET_ROUTES.handshake],
    queryFn: () => handshake(token),
    enabled: token ? true : false
  });
};
