import axios from "axios";
import axiosInstance from "../../services/axiosInstance";
import {
  DepositPayloadType,
  TransactionListingResponseType,
  TransferPayloadType,
  WalletBalanceType,
  WalletTransactionStatus,
} from "../../types/walletTypes";
import { GET_ROUTES, POST_ROUTES } from "./routes";
import { GetListingResponse, SortType } from "../../types/generalTypes";

export const getWalletBalance = () => {
  return axiosInstance.get<WalletBalanceType>(`${GET_ROUTES.balance}`);
};

export const getWalletTransactions = ({
  pageParam,
  search,
  sortData,
  status,
}: {
  pageParam?: number;
  search?: string;
  sortData?: SortType | null;
  status?: WalletTransactionStatus;
}) => {
  const hasSearched = search && search.trim().length > 0;
  const pagingParam =
    (!hasSearched || search.trim().length === 0) && pageParam
      ? `size=9&page=${pageParam ?? 1}`
      : "nopaging=true";
  const searchParam = hasSearched ? `&title=${search}` : "";
  const sort = sortData
    ? `&sort[${sortData.field}]=${sortData.order}`
    : "&sort[createdAt]=-1";
  const transactionStatus = status ? `&status=${status}` : "";
  return axiosInstance.get<GetListingResponse<TransactionListingResponseType>>(
    `${GET_ROUTES.transactions}?${pagingParam}${searchParam}${sort}${transactionStatus}`
  );
};

export const transferMoney = async (payload: TransferPayloadType) => {
  return await axiosInstance.post<any>(POST_ROUTES.transfer, payload);
};

export const depositMoney = async (payload: DepositPayloadType) => {
  return await axiosInstance.post<any>(POST_ROUTES.deposit, payload);
};

export const saveOnlineTransaction = async (data: any) =>
  await axiosInstance.post(POST_ROUTES.onlineTransfer, data);

// get  moyasar transaction
export const getMoyasarTransaction = async (paymentId: string) => {
  const data = await axios.get(GET_ROUTES.getMoyasarTransaction + paymentId, {
    auth: {
      username: `${process.env.REACT_APP_MOYASAR_SECRET_KEY}`,
      password: "",
    },
  });
  data.data.amount = data.data.amount / 100;
  return data;
};
