import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PARENT_ROUTES } from "../../../../parentRoutes";
import { setLogout } from "../../../../redux/auth";
import axiosInstance from "../../../../services/axiosInstance";
import { KEY_NAMES } from "../../../../utils/constants";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refreshToken = localStorage.getItem(KEY_NAMES.refreshToken);

  const logout = async () => {
    try {
      await axiosInstance.post("/security/logout", {
        refreshToken: refreshToken,
      });
      localStorage.removeItem(KEY_NAMES.accessToken);
      localStorage.removeItem(KEY_NAMES.refreshToken);
      localStorage.removeItem(KEY_NAMES.permissions);
      localStorage.removeItem(KEY_NAMES.merchantId);
      dispatch(setLogout());
      navigate(PARENT_ROUTES.login);
    } catch (error) {
      localStorage.removeItem(KEY_NAMES.accessToken);
      localStorage.removeItem(KEY_NAMES.refreshToken);
      localStorage.removeItem(KEY_NAMES.permissions);
      localStorage.removeItem(KEY_NAMES.merchantId);
      dispatch(setLogout());
      navigate(PARENT_ROUTES.login);
    }
  };
  return logout;
};
