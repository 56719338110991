import { useDispatch } from "react-redux";
import { useLogout } from "../../modules/core/components/customHooks/useLogout";
import { setToast } from "../../redux/toastSlice";
import { useIdleTimeout } from "./useIdleTimeout";

export function useAutoLogout(duration: number) {
  const logout = useLogout();
  const dispatch = useDispatch();

  const { user } = useIdleTimeout(duration, () => {
    if (user) {
      logout();
      dispatch(
        setToast({
          text: "You've been logged out due to inactivity",
          variant: "warning",
        })
      );
    }
  });
}
