import { ReactNode, useState } from "react";

type TooltipProps = {
  children: ReactNode;
  tooltipText: string;
  position?: "top" | "bottom" | "left" | "right";
  disabled?: boolean;
  width?: string;
};

const Tooltip = ({
  children,
  tooltipText,
  position = "top",
  disabled,
  width,
}: TooltipProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => setIsHovered(!isHovered);

  const getPositionClasses = () => {
    switch (position) {
      case "top":
        return "bottom-full left-1/2 transform -translate-x-1/2 mb-4";
      case "bottom":
        return "top-full left-1/2 transform -translate-x-1/2 mt-4";
      case "left":
        return "right-full top-1/2 transform -translate-y-1/2 mr-4";
      case "right":
        return "left-full top-1/2 transform -translate-y-1/2 ml-4";
      default:
        return "";
    }
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      {children}
      {isHovered && (
        <div
          className={`absolute text-wrap whitespace-nowrap bg-white border-2 border-custom-green text-text-black text-sm py-1 px-2 rounded z-50 ${getPositionClasses()}  ${
            disabled && "hidden"
          } ${width}`}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
