import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  DepositPayloadType,
  TransferPayloadType,
  WalletTransactionStatus,
} from "../../types/walletTypes";
import { useSuccessError } from "../../utils/hooks";
import { useChangeDealStatus } from "../merchantDeals/reactQueryHooks";
import {
  depositMoney,
  getMoyasarTransaction,
  getWalletBalance,
  getWalletTransactions,
  saveOnlineTransaction,
  transferMoney,
} from "./requests";
import { GET_ROUTES, POST_ROUTES } from "./routes";
import { SortType } from "../../types/generalTypes";

export const useGetWalletBalance = () => {
  return useQuery({
    queryKey: [GET_ROUTES.balance],
    queryFn: () => getWalletBalance(),
    retry: false,
  });
};

export const useGetWalletTransactions = ({
  pageParam,
  search,
  sortData,
  status,
}: {
  pageParam?: number;
  search?: string;
  sortData?: SortType | null;
  status?: WalletTransactionStatus;
}) => {
  return useQuery({
    queryKey: [GET_ROUTES.transactions, status, sortData, search, pageParam],
    queryFn: () =>
      getWalletTransactions({ pageParam, search, sortData, status }),
  });
};

export const useTransferMoneyToSystem = (dealId: string) => {
  const { mutate: changeDealStatus } = useChangeDealStatus();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.transfer],
    mutationFn: ({ payload }: { payload: TransferPayloadType }) =>
      transferMoney(payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.balance],
      });
      changeDealStatus({
        dealId,
        status: "approval",
        amount: data.data.amount ?? 0,
      });
    },
  });
};

export const useTransferMoneyToSeller = (dealId: string) => {
  const {
    mutate: changeDealStatus,
    isSuccess,
    isError,
    error,
  } = useChangeDealStatus();
  useSuccessError({
    error: error,
    isError: isError,
    isSuccess: isSuccess,
    successMessage: "Deal status updated successfully",
  });
  return useMutation({
    mutationKey: [POST_ROUTES.transfer, "seller"],
    mutationFn: ({ payload }: { payload: TransferPayloadType }) =>
      transferMoney(payload),
    onSuccess: () => {
      changeDealStatus({ dealId, status: "complete" });
    },
  });
};

export const useDepositMoney = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.deposit],
    mutationFn: ({ payload }: { payload: DepositPayloadType }) =>
      depositMoney(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.balance],
      });
    },
  });
};

export const useTransferSecurityFeeToSeller = (dealId: string) => {
  const {
    mutate: changeDealStatus,
    isSuccess,
    isError,
    error,
  } = useChangeDealStatus();
  const queryClient = useQueryClient();
  useSuccessError({
    error: error,
    isError: isError,
    isSuccess: isSuccess,
    successMessage: "Deal status updated successfully",
  });
  return useMutation({
    mutationKey: [POST_ROUTES.transfer, "security"],
    mutationFn: ({ payload }: { payload: TransferPayloadType }) =>
      transferMoney(payload),
    onSuccess: () => {
      changeDealStatus({ dealId, status: "disapprove" });
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.balance],
      });
    },
  });
};

export const useRefundMoneyToMerchant = (dealId: string) => {
  const {
    mutate: changeDealStatus,
    isSuccess,
    isError,
    error,
  } = useChangeDealStatus();
  const queryClient = useQueryClient();

  useSuccessError({
    error: error,
    isError: isError,
    isSuccess: isSuccess,
    successMessage: "Deal status updated successfully",
  });
  return useMutation({
    mutationKey: [POST_ROUTES.transfer, "refund"],
    mutationFn: ({ payload }: { payload: TransferPayloadType }) =>
      transferMoney(payload),
    onSuccess: () => {
      changeDealStatus({ dealId, status: "disapprove" });
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.balance],
      });
    },
  });
};

// save moyasar payment transaction into db
export const useSaveOnlineTransaction = (data: any) => {
  return useMutation({
    mutationKey: [POST_ROUTES.onlineTransfer],
    mutationFn: () => {
      data.amount = data.amount / 100;
      return saveOnlineTransaction(data);
    },
  });
};

export const useGetMoyasarTransaction = (id: string) => {
  return useQuery({
    queryKey: [GET_ROUTES.getMoyasarTransaction],
    queryFn: () => getMoyasarTransaction(id),
    retry: false,
  });
};
