import { useNavigate } from "react-router-dom";
import { RoleEnum } from "../../../../types/generalTypes";
import {
  NotificationActions,
  NotificationResponseType,
  NotificationsListingResponseType,
} from "../../../../types/notificationTypes";
import { capitalizeFirstLetter } from "../../../../utils/helpers";
import { GrayStarSvg } from "../../../../utils/svgIcons";
import { getRouteForAction } from "./functions";
import {
  useMarkAsRead,
  useMarkAsStarred,
  useMarkAsUnStarred,
} from "../../../../api/notifications/reactQueryHooks";
import { AxiosError } from "axios";
import { setToast } from "../../../../redux/toastSlice";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IoBulbSharp } from "../../../../utils/icons";

type NotificationsListingProps = {
  notifications: NotificationsListingResponseType;
  isStarred?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
const NotificationsListing = ({
  notifications,
  isStarred,
  setOpen,
}: NotificationsListingProps) => {
  const navigate = useNavigate();
  const { user } = useSelector(
    (state: RootState) => state.centeralizedStateData.user
  );
  const {
    mutate: markAsStarred,
    isError: isMarkAsStarredError,
    error: markAsStarredError,
  } = useMarkAsStarred();
  const {
    mutate: markAsUnStarred,
    isError: isMarkAsUnStarredError,
    error: markAsUnStarredError,
  } = useMarkAsUnStarred();
  const { mutate: markAsRead } = useMarkAsRead();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMarkAsStarredError || isMarkAsUnStarredError) {
      dispatch(
        setToast({
          text: markAsStarredError
            ? (markAsStarredError as AxiosError).message ??
              "Something went wrong"
            : (markAsUnStarredError as AxiosError).message ??
              "Something went wrong",
          variant: "error",
        })
      );
    }
  }, [
    dispatch,
    isMarkAsStarredError,
    isMarkAsUnStarredError,
    markAsUnStarredError,
    markAsStarredError,
  ]);

  const onNotificationClick = (notification: NotificationResponseType) => {
    markAsRead({ id: notification.notificationId });
    const role: RoleEnum | undefined = user?.role as RoleEnum | undefined;
    const action: NotificationActions = notification.action.split(
      "/"
    )[0] as NotificationActions;
    const id = notification.action.split("/")[1];
    if (role) {
      const route = getRouteForAction({
        action: action,
        id: id,
        role: role,
      });
      navigate(route);
      setOpen(false);
    }
  };

  const renderText = (message: string) => {
    const parts = message.split(/(:[^:]*:)/g).map((part, index) => {
      if (part.startsWith(":") && part.endsWith(":")) {
        return (
          <span
            key={index}
            className="text-black text-start font-semibold text-base"
          >
            {capitalizeFirstLetter(part.slice(1, -1))}
          </span>
        );
      } else {
        return (
          <span
            key={index}
            className="text-black text-start font-normal text-base"
          >
            {capitalizeFirstLetter(part)}
          </span>
        );
      }
    });

    return parts;
  };
  return (
    <>
      {notifications.length > 0 ? (
        notifications.map((notification) => {
          return (
            <div
              className={`hover:border-b-custom-green pt-3 border-b-[1px] hover:bg-custom-light-green cursor-pointer flex gap-x-2 justify-start p-2 items-center ${
                notification && !notification.isRead
                  ? "bg-custom-light-green"
                  : "bg-white"
              }`}
              onClick={() => onNotificationClick(notification)}
            >
              <div>
                <GrayStarSvg
                  onClick={(
                    event: React.MouseEvent<SVGSVGElement, MouseEvent>
                  ) => {
                    event.stopPropagation();
                    notification.isStarred
                      ? markAsUnStarred({
                          id: notification.notificationId,
                        })
                      : markAsStarred({
                          id: notification.notificationId,
                        });
                  }}
                  className={`${
                    notification && notification.isStarred
                      ? "hover:fill-white hover:stroke-border-green stroke-yellow-secondary fill-custom-yellow"
                      : "hover:fill-custom-yellow hover:stroke-yellow-secondary stroke-grayShades-borderLightGray "
                  } duration-300 transition-all size-6 mr-2`}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-start">
                  {renderText(notification && notification.message)}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <div
          className={`w-full flex justify-center items-center flex-col ${
            isStarred ? "mt-5" : "mt-1"
          }`}
        >
          <IoBulbSharp className="text-grayShades-dottedGray w-8 h-8" />
          <p
            className={`text-grayShades-dottedGray text-center font-medium text-base mt-1 select-none`}
          >
            {isStarred ? "No Starred Notifications" : "No Notifications"}
          </p>
        </div>
      )}
    </>
  );
};

export default NotificationsListing;
