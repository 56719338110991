export const MERCHANT_DEALS_ROUTES = {
  deal: "/deal/",
  deals: "/deals/",
  merchant: "/merchant",
  approval: "/approval",
};

export const GET_ROUTES = {
  deals: MERCHANT_DEALS_ROUTES.merchant + MERCHANT_DEALS_ROUTES.deals,
  //
  deal: MERCHANT_DEALS_ROUTES.merchant + MERCHANT_DEALS_ROUTES.deal,
  //
};

export const POST_ROUTES = {
  changeStatus: MERCHANT_DEALS_ROUTES.merchant + MERCHANT_DEALS_ROUTES.deals,
};
