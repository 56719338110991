import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { registerUser } from "../../../api/auth";
import { useHandshake } from "../../../api/public/reactQueryHooks";
import { useEmailExists } from "../../../api/security/reactQueryHooks";
import { PARENT_ROUTES } from "../../../parentRoutes";
import { setToast } from "../../../redux/toastSlice";
import { RoleEnum } from "../../../types/generalTypes";
import { SignupFieldNames, SignupFormType } from "../../../types/signupTypes";
import { convertToGeneralError } from "../../../utils/helpers";
import DetectOutsideClickWrapper from "../components/DetectOutsideClickWrapper";
import ErrorAndSuccessModal from "../components/ErrorAndSuccessModal";
import ModalParent from "../components/ModalParent";
import PersonalInfo from "../components/signup/PersonalInfo";
import { TabParent } from "../components/TabParent";
export const TabParentt = ({
  children,
  selectedSection,
}: {
  children: React.ReactNode;
  selectedSection: number;
}) => {
  const getTranslateX = () => {
    return `-${selectedSection}00%`;
  };
  return (
    <div
      style={{ transform: `translateX(${getTranslateX()})` }}
      className={`px-10 py-5 max-md:px-6 shrink-0 w-full transition-all duration-1000`}
    >
      {children}
    </div>
  );
};

const RepresentativeSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState(0);
  const [checkError, setCheckError] = useState(false);
  const [searchParam] = useSearchParams();
  const {
    data: handshakeResponse,
    error,
    isSuccess,
    isError,
    refetch,
  } = useHandshake(searchParam.get("token")!);
  const convedtedError = useMemo(() => convertToGeneralError(error), [error]);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: handshakeResponse?.data.email,
    password: "",
    confirmPassword: "",
    companyName: "",
    terms: false,
    type: RoleEnum.MERCHANT_REPRESENTATIVE,
    attributes: {
      crNumber: "",
      companySize: "",
      companyLegalName: "",
      investedCapitalUnit: "",
      crDocument: null,
      licenseDocument: null,
      investedCapital: null,
    },
  };

  const { mutateAsync: checkEmailExists, isPending } = useEmailExists();
  return (
    <div className="h-[calc(100vh-64px)]">
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: SignupFormType, { setSubmitting }) => {
          try {
            const res = await registerUser(
              {
                ...values,
                roleId: handshakeResponse?.data.roleId,
                type: RoleEnum.MERCHANT_REPRESENTATIVE,
              },
              false
            );
            dispatch(
              setToast({
                text:
                  res.data.message ||
                  "Account created successfully. Please check your email to verify your account",
                variant: "success",
              })
            );
            navigate(PARENT_ROUTES.login);
            setSubmitting(false);
          } catch (error) {
            if (error instanceof AxiosError) {
              dispatch(
                setToast({
                  text: error.response?.data.message || "Something went wrong",
                  variant: "error",
                })
              );
            }
          }
        }}
      >
        {({ handleSubmit, errors }) => (
          <Form
            className="h-full"
            onSubmit={(event) => {
              /**After Error Validations, call handle Submit */
              if (Object.keys(errors).length === 0) {
                handleSubmit();
              }
              event.preventDefault();
            }}
          >
            <div className="flex justify-center items-center bg-[#F9F9F9] h-full">
              <div
                className={`flex overflow-hidden max-lg:w-[80%] max-md:w-[90%]max-md:h-[97%] max-sm:w-[97%] w-[60%] h-[80%] bg-white shadow-div rounded-[32px]`}
              >
                <TabParent
                  index={0}
                  disAllowHeightReduce
                  fullHeight
                  selectedSection={selectedSection}
                >
                  <PersonalInfo
                    fieldNames={[
                      SignupFieldNames.companyName,
                      SignupFieldNames.firstName,
                      SignupFieldNames.lastName,
                      SignupFieldNames.password,
                      SignupFieldNames.email,
                    ]}
                    type={RoleEnum.MERCHANT_REPRESENTATIVE}
                    checkEmailExists={checkEmailExists}
                    isPending={isPending}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    email={handshakeResponse?.data.email}
                    checkError={checkError}
                    setCheckError={setCheckError}
                  />
                </TabParent>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {isError && (
        <DetectOutsideClickWrapper onClick={() => {}}>
          <ModalParent
            children={
              <ErrorAndSuccessModal
                isError={isError ?? false}
                isSuccess={isSuccess ?? false}
                loading={false}
                retry={() => {
                  refetch();
                }}
                retryButton={false}
                successButtonText="Got it"
                text={
                  convedtedError?.response?.data.message ??
                  "Something went wrong"
                }
                proceed={() => {
                  window.close();
                  navigate(`${PARENT_ROUTES.login}`);
                }}
                cancel={() => {
                  window.close();
                }}
              />
            }
          />
        </DetectOutsideClickWrapper>
      )}
    </div>
  );
};

export default RepresentativeSignup;
