export const PROFILE_ROUTE = {
  profile: "/users/profile/",
  users: "/users/",
};
export const GET_ROUTES = {
  getProfileById: PROFILE_ROUTE.users,
  getProfile: PROFILE_ROUTE.profile,
  getSubscriptionPlans: PROFILE_ROUTE.profile + "plans",
  getProgress: PROFILE_ROUTE.profile + "progress",
};
export const PUT_ROUTES = {
  workLocations: PROFILE_ROUTE.profile + "work-locations",
  basicInfo: PROFILE_ROUTE.profile + "basic-info",
  workExperiences: PROFILE_ROUTE.profile + "work-experiences",
  educations: PROFILE_ROUTE.profile + "educations",
  languages: PROFILE_ROUTE.profile + "languages",
  skills: PROFILE_ROUTE.profile + "skills",
  legalDocs: PROFILE_ROUTE.profile + "legal-docs",
  progress: PROFILE_ROUTE.profile + "profile-progress",
  companyDetails: PROFILE_ROUTE.profile + "company-details",
  digitalInformation: PROFILE_ROUTE.profile + "digital-informations",
  companyLocation: PROFILE_ROUTE.profile + "company-locations",
  industriesDetails: PROFILE_ROUTE.profile + "industries-details",
  legalDocuments: PROFILE_ROUTE.profile + "legal-documents",
};
