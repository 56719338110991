import { useMutation } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "react-query";
import {
  CompanyDetailsFormType,
  CompanyLegalDocumentsFormType,
  CompanyLocationsFormType,
  DigitalInformationCRUDType,
  IndustryDetailsFormTypes,
} from "../../types/merchantProfileTypes";
import {
  getCompletionProgress,
  getProfileById,
  getProfileData,
  getUserById,
  updateCompanyDetails,
  updateCompanyLocation,
  updateCompletionProgress,
  updateDigitalInformation,
  updateIndustryDetails,
  updateLegalDocuments,
} from "./requests";
import { GET_ROUTES, PROFILE_ROUTE, PUT_ROUTES } from "./routes";

export const useGetProfileById = (id: string | undefined) => {
  return useQuery({
    queryKey: [GET_ROUTES.getProfileById],
    queryFn: () => getProfileById(id!),
    enabled: id !== undefined,
  });
};

export const useGetProfileData = ({
  enabled,
  id,
}: {
  enabled?: boolean;
  id?: string;
}) => {
  return useQuery({
    queryKey: id ? [GET_ROUTES.getProfileById, id] : [GET_ROUTES.getProfile],
    queryFn: () => (id ? getProfileById(id!) : getProfileData()),
    enabled: enabled ?? true,
  });
};

export const useGetProfileCompletionProgress = () => {
  return useQuery({
    queryKey: [GET_ROUTES.getProgress],
    queryFn: () => getCompletionProgress(),
  });
};

export const useUpdateCompanyDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.companyDetails],
    mutationFn: ({ payload }: { payload: CompanyDetailsFormType }) =>
      updateCompanyDetails(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateDigitalInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.digitalInformation],
    mutationFn: ({ payload }: { payload: DigitalInformationCRUDType }) =>
      updateDigitalInformation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateCompanyLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [PUT_ROUTES.companyLocation],
    mutationFn: ({ payload }: { payload: CompanyLocationsFormType }) =>
      updateCompanyLocation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateLegalDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.legalDocuments],
    mutationFn: ({ payload }: { payload: CompanyLegalDocumentsFormType }) =>
      updateLegalDocuments(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateIndustryDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.industriesDetails],
    mutationFn: ({ payload }: { payload: IndustryDetailsFormTypes }) =>
      updateIndustryDetails(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateProfilePercentage = () => {
  return useMutation({
    mutationKey: [PUT_ROUTES.progress],
    mutationFn: ({ profileProgress }: { profileProgress: number }) =>
      updateCompletionProgress({ profileProgress }),
  });
};

export const useGetUserById = (userId: string | null) => {
  return useQuery({
    queryKey: [PROFILE_ROUTE.userById, userId],
    queryFn: () => getUserById(userId ?? ""),
    enabled: Boolean(userId),
  });
};
