export type NotificationResponseType = {
  _id: string;
  message: string;
  action: string;
  from: string;
  to: string[];
  isRead: boolean;
  notificationId: string;
  createdAt: string;
  updatedAt: string;
  isStarred?: boolean;
  __v: number;
};

export type NotificationsListingResponseType = NotificationResponseType[];

export enum NotificationActions {
  CreatedOffering = "CreatedOffering",
  InviteJob = "InviteJob",
  ApplyJob = "ApplyJob",
  Interview = "Interview",
  Contract = "Contract",
  Reject = "Reject",
  Deal = 'Deal',
  Review = 'Review'
}

export enum SocketEvents {
  notification = "notification",
  broadcast_message = "broadcast_message",
}
