import { useGetWalletBalance } from "../../../../api/wallet/reactQueryHooks";
import { WalletIcon } from "../../../../utils/svgIcons";
import Tooltip from "../Tooltip";

const WalletDisplay = ({onClick}:{onClick?:()=>void}) => {
  const { data, isLoading } = useGetWalletBalance();

  return (
    <div 
      onClick={onClick}
      className="flex items-center bg-box-green gap-2 p-2 rounded-xl sm:border-border-green sm:hover:bg-badge-border-green sm:border-2 mb-2 animate-dropdown sm:animate-none sm:my-0 sm:mb-0 sm:mx-8 duration-300 transition-all cursor-pointer"
      >
      <div>
        <WalletIcon className="" />
      </div>
      {isLoading ? (
        <div className="animate-pulse w-12 h-5 bg-grayShades-borderGray rounded-xl" />
      ) : data?.data?.data?.balance ? (
        <p className="text-dark-green font-semibold sm:text-base lg:text-lg   select-none">
          SAR {Number(data?.data?.data?.balance).toLocaleString()}
        </p>
      ) : (
        <Tooltip
          tooltipText="Add money to generate a wallet"
          position="bottom"
          width="lg:w-[100px]"
        >
          <p className="text-dark-green font-semibold sm:text-base lg:text-lg   select-none">
            No Wallet
          </p>
        </Tooltip>
      )}
    </div>
  );
};

export default WalletDisplay;
