import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToast } from "../redux/toastSlice";
import { AxiosError } from "axios";
import { setScroll } from "../redux/scrollSlice";
import { hasScrolledtoEnd } from "./helpers";

export const useSuccessError = ({
  error,
  isSuccess,
  isError,
  successMessage,
  successFunction,
}: {
  error: Error | null;
  isSuccess: boolean;
  isError: boolean;
  successMessage: string;
  successFunction?: () => void;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setToast({
          text: successMessage,
          variant: "success",
        })
      );
      successFunction && successFunction();
    }
  }, [isSuccess, dispatch, successMessage, successFunction]);
  useEffect(() => {
    if (isError) {
      dispatch(
        setToast({
          text:
            (
              (error as AxiosError).response?.data as {
                message: string;
              }
            )?.message ?? "Something went wrong",
          variant: "error",
        })
      );
    }
  }, [isError, dispatch, error, successMessage]);
};

export const useDetectScroll = ({
  customCondition,
  onScrollEnd,
}: {
  customCondition: boolean;
  onScrollEnd: () => void;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setScroll({
        onScroll: (event) => {
          if (hasScrolledtoEnd(event, customCondition)) {
            onScrollEnd();
          }
        },
      })
    );
    return () => {
      dispatch(setScroll({ onScroll: null }));
    };
  }, [customCondition, onScrollEnd, dispatch]);
};
