import { useMutation } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "react-query";
import { GET_ROUTES, PUT_ROUTES } from "./routes";
import {
  getProfileData,
  updateBasicInfo,
  updateDigitalInformation,
  updateEducation,
  updateIndustryDetails,
  updateLanguages,
  updateLegalDocs,
  updateSkills,
  updateWorkExperience,
  updateWorkLocations,
} from "./requests";
import {
  DigitalInformationCRUDType,
  IndustryDetailsFormTypes,
  UpdateBasicInfoFormType,
  UpdateCertificateListFormType,
  UpdateEducationListFormType,
  UpdateLanguagesListFormType,
  UpdateLegalDocsFormType,
  UpdateSkillsRequestAndFormType,
  UpdateWorkExperienceFormListType,
  WorkLocationsFormType,
} from "../../types/dealMakerProfileTypes";
export const useGetProfileData = ({
  enabled,
  id,
}: {
  enabled: boolean;
  id?: string;
}) => {
  return useQuery({
    queryKey: [
      id ? `${GET_ROUTES.getProfileById}${id}` : GET_ROUTES.getProfile,
    ],
    queryFn: () => getProfileData(id),
    enabled: Boolean(id) ? true : enabled,
  });
};

export const useUpdateDigitalInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.digitalInformation],
    mutationFn: ({ payload }: { payload: DigitalInformationCRUDType }) =>
      updateDigitalInformation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateWorkLocations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.workLocations],
    mutationFn: ({ payload }: { payload: WorkLocationsFormType }) =>
      updateWorkLocations(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateIndustryDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.industriesDetails],
    mutationFn: ({ payload }: { payload: IndustryDetailsFormTypes }) =>
      updateIndustryDetails(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateSkills = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.skills],
    mutationFn: ({ payload }: { payload: UpdateSkillsRequestAndFormType }) =>
      updateSkills(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateWorkExperience = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.workExperiences],
    mutationFn: ({
      workExperiencesPayload,
    }: {
      workExperiencesPayload: UpdateWorkExperienceFormListType;
    }) => updateWorkExperience(workExperiencesPayload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateEducation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.languages],
    mutationFn: ({
      certificatesPayload,
      educationPayload,
    }: {
      educationPayload: UpdateEducationListFormType;
      certificatesPayload: UpdateCertificateListFormType;
    }) => updateEducation(educationPayload, certificatesPayload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateBasicInfo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.basicInfo],
    mutationFn: ({ payload }: { payload: UpdateBasicInfoFormType }) =>
      updateBasicInfo(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateLanguages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.languages],
    mutationFn: ({ payload }: { payload: UpdateLanguagesListFormType }) =>
      updateLanguages(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};

export const useUpdateLegalDocs = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PUT_ROUTES.legalDocs],
    mutationFn: ({ payload }: { payload: UpdateLegalDocsFormType }) =>
      updateLegalDocs(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.getProfile],
      });
    },
  });
};
