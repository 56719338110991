export enum DEALMAKER_ROUTES {
  /** Common */
  anyOther = "/*",
  notFound = "/notFound",
  /** Job */
  apply = "apply/",
  applyAPI = "apply",
  job = "job/",
  jobs = "jobs/",
  resetJob = "merchant/job/",
  jobApplication = "jobApplication",
  //for payment testing
  deposit = "/deposit",
  /** Offering */
  offering = "offering/",
  create = "create",
  view = "view/",
  edit = "edit/", ///merchant/offering
  idPathParameter = "=id",
  resetMerchant = "merchant/offering/",
  // Deals
  deals = "deals/",
  /**Profile */
  profile = "profile/",
  quote = "/quote",
  /**Dispute Tickets */
  disputeTickets = "/dispute-tickets/",
  resetDisputeTickets = "/deal-maker/dispute-tickets/",
}
