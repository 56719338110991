import { io } from "socket.io-client";

export const connectSocket = (userId: string | undefined) => {
  const socket = io(process.env.REACT_APP_SOCKET_CONNECTION_URL ?? "", {
    reconnectionDelayMax: 10000,
    reconnection: false,
    query: {
      "client-id": userId ?? "",
    },
  });
  socket.connect();
  return socket;
};
