import axiosInstance from "../../services/axiosInstance";
import { GetListingResponse } from "../../types/generalTypes";
import { NotificationsListingResponseType } from "../../types/notificationTypes";
import { GET_ROUTES, POST_ROUTES } from "./routes";

export const getNotifications = async (pageParam?: number) => {
  const pagingParam = pageParam
    ? `size=9&page=${pageParam ?? 1}`
    : "nopaging=true";
  const { data } = await axiosInstance.get<
    GetListingResponse<NotificationsListingResponseType>
  >(`${GET_ROUTES.notifications}?${pagingParam}`);
  return data;
};

export const markAsRead = async (id: string) => {
  return axiosInstance.post(`${POST_ROUTES.markAsRead}`, { id: id });
};

export const markAllAsRead = async () => {
  return axiosInstance.post(`${POST_ROUTES.markAllAsRead}`);
};

export const markAsStarred = async (id: string) => {
  return axiosInstance.post(`${POST_ROUTES.markAsStarred}`, { id: id });
};

export const markAsUnStarred = async (id: string) => {
  return axiosInstance.post(`${POST_ROUTES.markAsUnStarred}`, { id: id });
};
