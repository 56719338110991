import { useState } from "react";
import MerchantOrSeller from "../components/signup/MerchantOrSeller";
import PersonalInfo from "../components/signup/PersonalInfo";
import CompanyInfo from "../components/signup/CompanyInfo";
import { Form, Formik } from "formik";
import {
  SignupAttributesType,
  SignupFieldNames,
  SignupFormType,
} from "../../../types/signupTypes";
import { registerUser } from "../../../api/auth";
import { useDispatch } from "react-redux";
import { setToast } from "../../../redux/toastSlice";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { PARENT_ROUTES } from "../../../parentRoutes";
import { useEmailExists } from "../../../api/security/reactQueryHooks";
import { RoleEnum } from "../../../types/generalTypes";
import { TabParent } from "../components/TabParent";
export const TabParentt = ({
  children,
  selectedSection,
}: {
  children: React.ReactNode;
  selectedSection: number;
}) => {
  const getTranslateX = () => {
    return `-${selectedSection}00%`;
  };
  return (
    <div
      style={{ transform: `translateX(${getTranslateX()})` }}
      className={`px-10 py-5 max-md:px-6 shrink-0 w-full transition-all duration-1000`}
    >
      {children}
    </div>
  );
};

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState(0);
  const [checkError, setCheckError] = useState(false);
  const [skip, setSkip] = useState(false);
  const [type, setType] = useState<"merchant" | "seller" | "dealMaker">(
    "merchant"
  );
  const initialValues: SignupFormType = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    type: type,
    terms: false,
    attributes: {
      crNumber: "",
      companySize: "",
      companyLegalName: "",
      investedCapitalUnit: "",
      crDocument: null,
      licenseDocument: null,
      investedCapital: null,
    },
  };
  const companyInfoFieldNames = [
    SignupFieldNames.crNumber,
    SignupFieldNames.companySize,
    SignupFieldNames.companyLegalName,
    SignupFieldNames.investedCapital,
    SignupFieldNames.investedCapitalUnit,
    SignupFieldNames.crDocument,
    SignupFieldNames.licenseDocument,
  ];
  const isOptional = (values: SignupFormType) => {
    if (skip) {
      return true;
    } else {
      return companyInfoFieldNames.every(
        (name) =>
          !Boolean(values?.attributes?.[name as keyof SignupAttributesType])
      );
    }
  };

  const { mutateAsync: checkEmailExists, isPending } = useEmailExists();
  const companyInfoFields =
    type === "dealMaker" || type === "seller"
      ? []
      : [
          SignupFieldNames.attributescrNumber,
          SignupFieldNames.attributescompanySize,
          SignupFieldNames.attributescompanyLegalName,
          SignupFieldNames.attributesinvestedCapital,
          SignupFieldNames.attributesinvestedCapitalUnit,
          SignupFieldNames.attributescrDocument,
          SignupFieldNames.attributeslicenseDocument,
        ];

  const customCSS: React.CSSProperties = {
    overflowY: "auto",
    padding: "0px",
  };
  return (
    <div className="h-[calc(100vh-64px)]">
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: SignupFormType,
          { setErrors, setSubmitting }
        ) => {
          try {
            let emailExistsData;
            if (type === RoleEnum.SELLER) {
              emailExistsData = await checkEmailExists(values.email);
            }
            if (type === RoleEnum.SELLER && emailExistsData?.data.emailExist) {
              setErrors({ email: "Email already exists" });
              setSubmitting(false);
            } else {
              await registerUser({ ...values, type }, isOptional(values));
              dispatch(
                setToast({
                  text: "Account created successfully. Please check your email to verify your account",
                  variant: "success",
                })
              );
              navigate(PARENT_ROUTES.login);
              setSubmitting(false);
            }
          } catch (error) {
            if (error instanceof AxiosError) {
              dispatch(
                setToast({
                  text: error.response?.data.message || "Something went wrong",
                  variant: "error",
                })
              );
            }
          }
        }}
      >
        {({ handleSubmit, setFieldTouched, validateField, errors, values }) => (
          <Form
            className="h-full"
            onSubmit={async (event) => {
              /**After Error Validations, call handle Submit */
              if (values.terms === false) {
                setCheckError(true);
              }
              companyInfoFields.forEach(async (field) => {
                await setFieldTouched(field);
                await validateField(field);
              });

              if (
                Object.keys(errors).length === 0 &&
                values.terms === true &&
                ((values.attributes.licenseDocument &&
                  values.attributes.crDocument) ||
                  type === "dealMaker" ||
                  type === "seller" ||
                  isOptional(values))
              ) {
                handleSubmit();
              }
              event.preventDefault();
            }}
          >
            <div className="flex justify-center bg-[#F9F9F9] h-full">
              <div
                className={`flex overflow-hidden xl:w-[600px]  w-[600px] md:my-12 md:h-fit bg-white sm:shadow-md  landscape:max-md:rounded-none rounded-xl`}
              >
                <TabParent
                  index={0}
                  fullHeight
                  disAllowHeightReduce
                  selectedSection={selectedSection}
                  customCSS={customCSS}
                >
                  <MerchantOrSeller
                    type={type}
                    setSelectedSection={setSelectedSection}
                    setType={setType}
                  />
                </TabParent>
                <TabParent
                  index={1}
                  disAllowHeightReduce
                  fullHeight
                  selectedSection={selectedSection}
                  customCSS={customCSS}
                >
                  <PersonalInfo
                    fieldNames={[
                      SignupFieldNames.companyName,
                      SignupFieldNames.firstName,
                      SignupFieldNames.lastName,
                      SignupFieldNames.password,
                      SignupFieldNames.email,
                      SignupFieldNames.terms,
                    ]}
                    type={type}
                    checkEmailExists={checkEmailExists}
                    isPending={isPending}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    checkError={checkError}
                    setCheckError={setCheckError}
                  />
                </TabParent>
                <TabParent
                  index={2}
                  fullHeight
                  disAllowHeightReduce
                  selectedSection={selectedSection}
                  customCSS={customCSS}
                >
                  <CompanyInfo
                    fieldNames={
                      type === "dealMaker" || type === "seller"
                        ? []
                        : [
                            SignupFieldNames.crNumber,
                            SignupFieldNames.companySize,
                            SignupFieldNames.companyLegalName,
                            SignupFieldNames.investedCapital,
                            SignupFieldNames.investedCapitalUnit,
                            SignupFieldNames.crDocument,
                            SignupFieldNames.licenseDocument,
                          ]
                    }
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    setSkip={setSkip}
                    skip={skip}
                  />
                </TabParent>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Signup;
