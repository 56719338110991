import { FileWithPath } from "react-dropzone";
import { attributesType } from "./generalTypes";

export type SignupRequestType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  terms:boolean;
  companyName: string;
  type: "merchant" | "seller" | string;
  attributes: { company: attributesType }; //SignupAttributesType
};

export type SignupFormType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword?: string;
  companyName: string;
  terms:boolean;
  type: "merchant" | "seller" | string;
  attributes: SignupAttributesType; //SignupAttributesType
  roleId?: string
};
export type SignupAttributesType = {
  crNumber: string;
  companySize: string;
  companyLegalName: string;
  investedCapital: number | null;
  investedCapitalUnit: string;
  crDocument: { fileWithPath: FileWithPath; src: string } | null;
  licenseDocument: { fileWithPath: FileWithPath; src: string } | null;
};
export enum SignupFieldNames {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  password = "password",
  companyName = "companyName",
  type = "type",
  crNumber = "crNumber",
  companySize = "companySize",
  companyLegalName = "companyLegalName",
  investedCapital = "investedCapital",
  investedCapitalUnit = "investedCapitalUnit",
  crDocument = "crDocument",
  licenseDocument = "licenseDocument",
  attributescrNumber = "attributes.crNumber",
  attributescompanySize = "attributes.companySize",
  attributescompanyLegalName = "attributes.companyLegalName",
  attributesinvestedCapital = "attributes.investedCapital",
  attributesinvestedCapitalUnit = "attributes.investedCapitalUnit",
  attributescrDocument = "attributes.crDocument",
  attributeslicenseDocument = "attributes.licenseDocument",
  terms = "terms",
}
