import { useQueries, useQuery } from "@tanstack/react-query";
import { getDocumentById, getDocumentMetaData } from "./requests";
import { GET_ROUTES } from "./routes";

export const useGetDocumentById = (id: string, isEnabled: boolean) => {
  return useQuery({
    queryKey: [GET_ROUTES.getMedia, id],
    queryFn: () => getDocumentById(id),
    enabled: isEnabled,
  });
};

export const useGetDocumentMetaData = (id: string, isEnabled: boolean) => {
  return useQuery({
    queryKey: [GET_ROUTES.getMetaData, id],
    queryFn: () => getDocumentMetaData(id),
    enabled: isEnabled,
  });
};

export const useGetDocumentsMetaData = (ids: string[] | null) => {
  const results = useQueries({
    queries: ids
      ? ids.map((id) => ({
          queryKey: [GET_ROUTES.getMetaData, id],
          queryFn: () => getDocumentMetaData(id),
          enabled: Boolean(id),
        }))
      : [],
  });
  let hasError;
  if (results.every((data) => data.data !== undefined)) {
    hasError = false;
  } else {
    hasError = true;
  }
  const data = results.map((result) => result.data);
  const isLoading =
    ids?.every((id) => id !== null) &&
    results.some((result) => result.isLoading);
  return { data, hasError, isLoading };
};
