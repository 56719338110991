type CustomModalProps = {
  children: React.ReactNode;
};
const ModalParent = ({ children }: CustomModalProps) => {
  return (
    <div
      className={`h-screen w-full p-5 z-50 top-0 bottom-0 left-0 right-0 bg-black bg-opacity-20 fixed flex justify-center items-center`}
    >
      {children}
    </div>
  );
};

export default ModalParent;
