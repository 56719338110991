export const WALLET_ROUTES = {
  wallets: "/wallets",
  balance: "/balance",
  transfer: "/transfer",
  deposit: "/deposit",
  wesell: "/wesell",
  transactions: "/transactions/inappcredit",
};

export const GET_ROUTES = {
  balance: WALLET_ROUTES.wallets + WALLET_ROUTES.balance,
  transactions: WALLET_ROUTES.wallets + WALLET_ROUTES.transactions,
  getMoyasarTransaction: "https://api.moyasar.com/v1/payments/",
};

export const POST_ROUTES = {
  transfer: WALLET_ROUTES.wallets + WALLET_ROUTES.transfer,
  deposit: WALLET_ROUTES.wallets + WALLET_ROUTES.deposit,
  transferToSystem:
    WALLET_ROUTES.wallets + WALLET_ROUTES.transfer + WALLET_ROUTES.wesell,
  onlineTransfer: "/wallets/transfer/online",
};
