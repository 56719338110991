import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getNotifications,
  markAllAsRead,
  markAsRead,
  markAsStarred,
  markAsUnStarred,
} from "./requests";
import { GET_ROUTES, POST_ROUTES } from "./routes";

export const useGetNotifications = (page: number) => {
  return useInfiniteQuery({
    queryKey: [GET_ROUTES.notifications, page],
    queryFn: ({ pageParam }) => getNotifications(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.page + 1;
      return nextPage <= Math.ceil(lastPage.total / lastPage.size)
        ? nextPage
        : undefined;
    },
  });
};

export const useMarkAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.markAsRead],
    mutationFn: ({ id }: { id: string }) => markAsRead(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ROUTES.notifications] });
    },
  });
};

export const useMarkAllAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.markAllAsRead],
    mutationFn: () => markAllAsRead(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ROUTES.notifications] });
    },
  });
};

export const useMarkAsStarred = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.markAsStarred],
    mutationFn: ({ id }: { id: string }) => markAsStarred(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ROUTES.notifications] });
    },
  });
};
export const useMarkAsUnStarred = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.markAsUnStarred],
    mutationFn: ({ id }: { id: string }) => markAsUnStarred(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ROUTES.notifications] });
    },
  });
};
