import BoxSkeleton from "./loaders/BoxSkeleton";
import { IoIosCamera } from "../../../utils/icons";
import { NoProfileImage, NoImage } from "../../../utils/images";
import React, { useEffect, useState } from "react";
import { getImageSrcFromMedia } from "../../../utils/helpers";
import pdfIcon from "../../../assets/icons/pdfIcon.png";
import pptIcon from "../../../assets/icons/ppt.png";
import docsIcon from "../../../assets/icons/docs.png";
import { useGetDocumentMetaData } from "../../../api/media/reactQueryHooks";
import {
  HeightType,
  ImageExtensionType,
  MarginHorizontalType,
  MarginVerticalType,
  WidthType,
} from "../../../types/generalTypes";

export type CustomImageCommonProps = {
  imageId?: string;
  src?: string;
};
export type CustomImageSpecificProps = {
  variant?:
    | "custom"
    | "small"
    | "semi-small"
    | "medium"
    | "semi-medium"
    | "large";
  radius?: "rounded-full" | "rounded-[10px]";
  customWidth?: WidthType | string;
  customHeight?: HeightType | string;
  isEdit?: boolean;
  isLoading?: boolean;
  noShadow?: boolean;
  my?: MarginVerticalType;
  mx?: MarginHorizontalType;
  hasBorder?: boolean;
  object?: "object-contain" | "object-cover" | "object-scale-down";
  isProfilePicture?: boolean;
  onImageClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
};
const CustomImage = ({
  src,
  customHeight,
  customWidth,
  radius = "rounded-[10px]",
  imageId,
  variant = "medium",
  isEdit,
  my = "my-0",
  mx = "mx-0",
  isLoading,
  noShadow,
  hasBorder,
  object = "object-contain",
  isProfilePicture,
  onImageClick,
}: CustomImageCommonProps & CustomImageSpecificProps) => {
  const { data } = useGetDocumentMetaData(imageId ?? src!, Boolean(imageId));
  const imageFileType: ImageExtensionType = data?.data
    .ext as ImageExtensionType;
  const [source, setSrc] = useState<string>(
    imageFileType === ".pdf"
      ? pdfIcon
      : imageFileType === ".pptx"
      ? pptIcon
      : imageFileType === ".docs" || imageFileType === ".docx"
      ? docsIcon
      : getImageSrcFromMedia(imageId || "")
  );
  const handleImageError = () => {
    setSrc(isProfilePicture ? NoProfileImage : NoImage);
  };
  useEffect(() => {
    if (imageId) {
      const newSource =
        imageFileType === ".pdf"
          ? pdfIcon
          : imageFileType === ".pptx"
          ? pptIcon
          : imageFileType === ".docs" || imageFileType === ".docx"
          ? docsIcon
          : getImageSrcFromMedia(imageId || "");
      setSrc(newSource);
    } else {
      setSrc(
        src?.includes("application/pdf")
          ? pdfIcon
          : src?.includes("application/vnd.openxmlformats")
          ? docsIcon
          : src?.includes("application/vnd.ms-powerpoint")
          ? pptIcon
          : src ?? (isProfilePicture ? NoProfileImage : NoImage)
      );
    }
  }, [imageId, src, imageFileType, isProfilePicture]);
  return (
    <div
      className={`relative ${my} ${mx} ${radius} ${
        imageId !== "." &&
        imageId !== undefined &&
        !noShadow &&
        "shadow-datagrid"
      } ${
        variant === "small"
          ? "w-10 h-10"
          : variant === "semi-small"
          ? "w-12 h-12"
          : variant === "medium"
          ? "w-32 h-32"
          : variant === "semi-medium"
          ? "w-40 h-32"
          : variant === "custom"
          ? `${customWidth} ${customHeight}`
          : "w-52 h-52"
      }`}
    >
      {isLoading ? (
        <BoxSkeleton
          className={`${
            variant === "small"
              ? "w-10 h-10"
              : variant === "semi-small"
              ? "w-12 h-12"
              : variant === "medium"
              ? "w-32 h-32"
              : variant === "semi-medium"
              ? "w-40 h-32"
              : variant === "custom"
              ? `${customWidth} ${customHeight}`
              : "w-52 h-52"
          }`}
        />
      ) : imageId !== "." && imageId !== undefined && imageId !== "" ? (
        <img
          onClick={onImageClick}
          alt=""
          className={`${
            variant === "small"
              ? "w-10 h-10"
              : variant === "semi-small"
              ? "w-12 h-12"
              : variant === "medium"
              ? "w-32 h-32"
              : variant === "semi-medium"
              ? "w-40 h-32"
              : variant === "custom"
              ? `${customWidth} ${customHeight}`
              : "w-52 h-52 max-sm:h-32"
          } max-sm:w-full ${radius} ${
            imageId !== "." &&
            imageId !== undefined &&
            !noShadow &&
            "shadow-datagrid"
          } ${object} ${hasBorder && "border-2 border-grayShades-imageBorder"}`}
          src={source}
          onError={handleImageError} // Handle image loading error
        />
      ) : (
        <img
          onClick={onImageClick}
          alt=""
          className={`${
            variant === "small"
              ? "w-10 h-10"
              : variant === "semi-small"
              ? "w-12 h-12"
              : variant === "medium"
              ? "w-32 h-32"
              : variant === "semi-medium"
              ? "w-40 h-32"
              : variant === "custom"
              ? `${customWidth} ${customHeight}`
              : "w-52 h-52 max-sm:h-32"
          } max-sm:w-full ${radius} ${
            imageId !== "." &&
            imageId !== undefined &&
            !noShadow &&
            "shadow-datagrid"
          } ${object} ${hasBorder && "border-2 border-grayShades-imageBorder"}`}
          src={
            src?.includes("application/pdf")
              ? pdfIcon
              : src?.includes("application/vnd.openxmlformats")
              ? docsIcon
              : src?.includes("application/vnd.ms-powerpoint")
              ? pptIcon
              : src ?? NoProfileImage
          }
        />
      )}
      {isEdit && (
        <div className="absolute cursor-pointer flex items-center justify-center bottom-2 right-2 rounded-full bg-grayShades-datagrid size-7">
          <IoIosCamera className="text-white" />
        </div>
      )}
    </div>
  );
};

export default React.memo(CustomImage);
