import { HeightType, WidthType } from "../../../../types/generalTypes";

type SubscriptionBoxSkeletonProps = {
  width?: WidthType;
  height?: HeightType;
  className?: string;
};
const BoxSkeleton = ({
  width = "w-72",
  height = "h-96",
  className,
}: SubscriptionBoxSkeletonProps) => {
  return (
    <div
      className={`flex flex-col p-3 rounded-2xl animate-blink hover:shadow-datagrid shadow-subscriptionShadow bg-grayShades-borderLightGray items-start ${
        !className && height
      } ${!className && width} ${!className && "min-w-64"} ${className} `}
    ></div>
  );
};

export default BoxSkeleton;
