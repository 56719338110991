import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import building from "../../../../assets/images/building.png";
import seller from "../../../../assets/images/seller.png";
import { PARENT_ROUTES } from "../../../../parentRoutes";
import { RoleEnum } from "../../../../types/generalTypes";
import IconHeadingBox from "../IconHeadingBox";
import CustomButton from "../CustomButton";

type MerchantOrSellerProps = {
  setSelectedSection: Dispatch<SetStateAction<number>>;
  setType: Dispatch<SetStateAction<"merchant" | "seller" | "dealMaker">>;
  type: string;
};
const MerchantOrSeller = ({
  setSelectedSection,
  setType,
  type,
}: MerchantOrSellerProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-8 flex flex-col items-center max-md:justify-center overflow-x-hidden h-fit pb-16 ">
      <p className="text-2xl max-md:text-3xl max-sm:text-2xl  ">
        Join as a Seller or a Merchant
      </p>
      <div className="flex max-md:flex-col max-md:justify-center gap-10 px-[2px] max-lg:gap-7 max-md:gap-4 mt-5 max-md:mt-5 ">
        <IconHeadingBox
          iconSrc={building}
          heading="I am a Merchant,"
          subHeading="I want to hire sellers"
          setType={() => setType(RoleEnum.MERCHANT)}
          type={type === RoleEnum.MERCHANT ? true : false}
          onClick={() => {
            setType(RoleEnum.MERCHANT);
          }}
        />
        <IconHeadingBox
          heading="I am a seller, looking"
          iconSrc={seller}
          subHeading="to sell prodcuts or services"
          setType={() => setType(RoleEnum.SELLER)}
          type={type === RoleEnum.SELLER ? true : false}
          onClick={() => {
            setType(RoleEnum.SELLER);
          }}
        />
      </div>
      <CustomButton
        text={`Join as ${type === RoleEnum.MERCHANT ? "Merchant" : "Seller"}`}
        fontFamily=" "
        fontSize="large"
        variant="primary"
        textVariant="green"
        noHover
        onClick={() => {
          setSelectedSection(1);
        }}
        className=" mt-8 max-md:mt-6 p-5"
      />

      <div className="flex flex-wrap justify-center mt-5 mb-8">
        <p className="text-grayShades-customGray text-center font-normal   text-base px-1">
          {`Already have an account? `}
          <CustomButton
            text=" Sign in"
            variant="text"
            size="medium"
            fontSize="medium"
            noHover
            onClick={() => navigate(PARENT_ROUTES.login)}
          />
        </p>
      </div>
    </div>
  );
};

export default MerchantOrSeller;
