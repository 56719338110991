import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PARENT_ROUTES } from "../../../parentRoutes";
import { RootState } from "../../../redux/store";
import { RoleEnum } from "../../../types/generalTypes";
import { MERCHANT_ROUTES } from "../../merchant/routes";
import CustomButton from "./CustomButton";
import WalletDisplay from "./wallet/WalletDisplay";
import { IoClose, IoMenu } from "../../../utils/icons";

type MenuItemsType = {
  name: string;
  link: string;
  active: boolean;
};

interface MobileNavMenuProps {
  menuItemsMerchant: MenuItemsType[];
  role: RoleEnum;
  profilePicture: string;
  logout: () => void;
  menuItemsDealMaker: MenuItemsType[];
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNavigateInAppCredits: () => void
}

const MobileNavMenu: FC<MobileNavMenuProps> = ({
  role,
  profilePicture,
  logout,
  menuItemsDealMaker,
  menuItemsMerchant,
  isMenuOpen,
  setIsMenuOpen,
  handleNavigateInAppCredits
}) => {
  const { user } = useSelector(
    (state: RootState) => state.centeralizedStateData.user
  );
  const navigate = useNavigate();

  const menuItems =
    role === RoleEnum.MERCHANT ? menuItemsMerchant : menuItemsDealMaker;

  return (
    <div className="lg:hidden bg-white">
      {isMenuOpen ? (
        <IoClose onClick={() => setIsMenuOpen(!isMenuOpen)} />
      ) : (
        <IoMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />
      )}
      <div className="h-full">
        {isMenuOpen && (
          <div className="absolute transition-transform animate-slideright bg-white top-16 border-custom-green border-[1px] border-t-0 rounded-b-xl right-0 w-full z-50 flex flex-col p-5">
            <div className="text-start -ml-1 my-3">
              <CustomButton
                textLeftPadding="pl-0"
                text={user?.name ?? ""}
                variant="text"
                onClick={() => {
                  navigate(
                    `${PARENT_ROUTES.merchant}/${MERCHANT_ROUTES.profile}`
                  );
                  setIsMenuOpen(false);
                }}
                leftIcon={
                  <img
                    src={profilePicture}
                    alt="profilepic"
                    className="w-10 h-10 mr-2"
                  />
                }
                textVariant="gray"
                noHover
              />
            </div>
            <WalletDisplay onClick={()=>{
                  handleNavigateInAppCredits();
                   setIsMenuOpen(false);
                  }}  />
            {menuItems.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  navigate(item.link);
                  setIsMenuOpen(false);
                }}
                className="flex flex-col w-full mb-4 cursor-pointer bg-white"
              >
                <p
                  className={`py-2 ${
                    item.active
                      ? "text-start text-secondary-green"
                      : "text-start text-grayShades-customGray"
                  }`}
                >
                  {item.name}
                </p>
                <div
                  className={`${
                    item.active ? "w-full" : "w-0"
                  } transition-[width] h-[2px] `}
                />
              </div>
            ))}

            <CustomButton
              text="Logout"
              width="w-full"
              onClick={logout}
              variant="text"
              textVariant="red"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNavMenu;
