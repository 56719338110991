import { useMutation } from "@tanstack/react-query";
import { POST_ROUTES } from "./routes";
import { sendResetEmail, checkEmailExists, updatePassword } from "./requests";

export const useSendResetEmail = () => {
  return useMutation({
    mutationKey: [POST_ROUTES.resetPassword],
    mutationFn: ({ email }: { email: string }) => sendResetEmail(email),
  });
};

export const useEmailExists = () => {
  return useMutation({
    mutationKey: [POST_ROUTES.emailExists],
    mutationFn: (email: string) => checkEmailExists(email),
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationKey: [POST_ROUTES.updatePassword],
    mutationFn: ({ token, password }: { token: string, password: string }) => updatePassword(token, password),
  });
};
