export const KEY_NAMES = {
  accessToken: "accessToken",
  permissions: "permissions",
  merchantId: "merchantId",
  refreshToken: "refreshToken",
  rememberMe: "rememberMe",
  email: "email",
  password: "password",
};

export const MAX_FILES_UPLOAD_COUNT = 5;
export const QUOTATION_VAT = 15; // 15%
export const SECURITY_FEES = 10; // 10%
export const PLATFORM_FEES = 50; // SAR

export const CURRENCY = {
  SAR: "SAR",
};

export enum MediaExtension {
  MP4 = ".mp4",
  PDF = ".pdf",
  JPG = ".jpg",
  PNG = ".png",
}

export const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const PROFICIENCY_LEVELS = [
  { id: "0", name: "Please Select Proficiency Level" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
];

export const CardPaymentErrors = [
  {
    includes: "INSUFFICIENT_FUNDS",
    returnText: "Insufficient Funds",
  },
  {
    includes: "UNSPECIFIED_FAILURE",
    returnText: "An unspecified failure occured",
  },
  {
    includes: "DECLINED",
    returnText: "Your card was declined",
  },
  {
    includes: "BLOCKED",
    returnText: "Transaction blocked by the acquiring bank",
  },
  {
    includes: "EXPIRED_CARD",
    returnText: "Your card has expired",
  },
  {
    includes: "TIMED_OUT",
    returnText: "Connection to the bank timed out, please try again",
  },
  {
    includes:
      "Value 'xxxx' is invalid. value: xxxx - reason: Invalid secure code length",
    returnText: "Invalid security code",
  },
  {
    includes: "REFERRED",
    returnText: "There is a problem with your credit card number",
  },
  {
    includes: "3-D Secure transaction attempt failed (AUTHENTICATION_FAILED)",
    returnText: "Authentication failed, please try again",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (AUTHENTICATION_ATTEMPTED)",
    returnText: "Authentication failed, please check your 3D-Secure enrollment",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (AUTHENTICATION_NOT_AVAILABLE)",
    returnText:
      "Authentication not available, please check your 3D-Secure enrollment",
  },
  {
    includes: "3-D Secure transaction attempt failed (Missing parameter)",
    returnText: "Authentication error, please try again",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (Relationship not found for merchantID XXX, card type VC)",
    returnText: "Merchant account not configured for Visa transactions",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (Relationship not found for merchantID XXX, card type MC)",
    returnText: "Merchant account not configured for MasterCard transactions",
  },
  {
    includes: "3-D Secure transaction attempt failed (CARD_NOT_ENROLLED)",
    returnText: "Card not enrolled in 3D-Secure, please contact your bank",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (Value 'xxx' is invalid. Cannot determine card brand.)",
    returnText: "Invalid card number",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (Value 'xxx' is invalid. Unable to determine card payment.)",
    returnText: "Invalid card number",
  },
  {
    includes:
      "3-D Secure transaction attempt failed (Value 'xxx' is invalid. Amount exceeds maximum allowed limit)",
    returnText: "Transaction amount exceeds the limit",
  },
  {
    includes: "Allowed time frame for transaction has been expired",
    returnText: "Transaction timed out, please try again",
  },
];
