import axiosInstance from "../../services/axiosInstance";
import { GetListingResponse } from "../../types/generalTypes";
import {
  MerchantDealFilterType,
  MerchantDealListingType,
} from "../../types/merchantDealsTypes";
import { GET_ROUTES, POST_ROUTES } from "./routes";

export const getAllMerchantDeals = async ({
  pageNumber,
  searchText,
  filterBy = [
    "buyerInfo.company.name",
    "dealMaker.firstName",
    "job.title",
    "status",
  ],
}: {
  pageNumber: number;
  searchText: string;
  filterBy?: MerchantDealFilterType;
}) => {
  const params: Record<string, any> =
    filterBy.length > 1
      ? {
          page: pageNumber ?? 1,
          size: 9,
          filterOpt: "or", // Ensures that it searches for matches in any of the given fields
        }
      : {
          page: pageNumber ?? 1,
          size: 9,
        };
  filterBy.forEach((filter) => {
    if (filter === "dealMaker.firstName") {
      params[filter] = searchText.split(" ")[0];
      params["dealMaker.lastName"] = searchText.split(" ")[1] ?? searchText;
    } else {
      params[filter] = searchText;
    }
  });

  return await axiosInstance.get<GetListingResponse<MerchantDealListingType[]>>(
    `${GET_ROUTES.deals}`,
    {
      params: params,
    }
  );
};

export const getAllMerchantDealsForBuyers = async ({
  pageNumber,
  searchText,
  selectedJobId,
  selectedSellerId,
}: {
  pageNumber: number;
  searchText: string;
  selectedJobId?: string;
  selectedSellerId?: string;
}) => {
  return await axiosInstance.get<GetListingResponse<MerchantDealListingType[]>>(
    `${GET_ROUTES.deals}`,
    {
      params: {
        page: pageNumber ?? 1,
        size: 9,
        "job.jobId": selectedJobId,
        dealMakerId: selectedSellerId,
        "buyerInfo.company.name": searchText,
      },
    }
  );
};

export const getMerchantDealById = async ({ id }: { id: string }) => {
  return await axiosInstance.get<MerchantDealListingType>(
    `${GET_ROUTES.deals}${id}`
  );
};

export const changeDealStatus = async ({
  dealId,
  status,
  amount,
}: {
  dealId: string;
  status: "approval" | "complete" | "disapprove";
  amount?: number;
}) => {
  const url = POST_ROUTES.changeStatus + dealId + "/" + status;
  if (status === "approval") {
    return await axiosInstance.post(url, {
      amount,
    });
  }
  return await axiosInstance.post(url);
};
