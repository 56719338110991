import { BiSolidError } from "react-icons/bi";
import { CancelConfirmationModalType } from "../../../types/generalTypes";
import CustomButton from "./CustomButton";

const CancelConfirmationModal = ({
  text = "Are you sure?",
  errorIcon = false,
  cancel,
  proceed,
  proceedButtonText = "Confirm",
  cancelButtonText = "Cancel",
  proceedButtonColor = "green",
  confirmDisabled = false,
}: CancelConfirmationModalType) => {
  return (
    <div className="w-fit h-fit relative px-1 py-10 flex flex-col bg-white rounded-2xl">
      <div className="px-12">
        {errorIcon && <div className="w-full justify-center flex mb-4">
          <BiSolidError className="text-6xl text-error" />
        </div>}
        <div className="mb-6 w-full flex justify-center">
          <p className="text-2xl w-3/4 font-semibold">{text}</p>
        </div>
        <div className="mt-3 flex gap-3 items-center justify-center">
          <CustomButton
            disabled={confirmDisabled}
            variant={proceedButtonColor === "red" ? `error` : "primary"}
            onClick={proceed}
            text={proceedButtonText}
            size="medium"
          />
          <CustomButton
            variant="secondary"
            onClick={cancel}
            text={cancelButtonText}
            size="medium"
          />
        </div>
      </div>
    </div>
  );
};

export default CancelConfirmationModal;
