import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dealId: "",
};

const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setDealId(state, action) {
      state.dealId = action.payload;
    },
    clearDealId(state) {
      state.dealId = "";
    },
  },
});

export const { setDealId, clearDealId } = dealSlice.actions;
export default dealSlice.reducer;
