export const PROFILE_ROUTE = {
  profile: "/users/profile/",
  userById: "/users/",
};
export const GET_ROUTES = {
  getProfileById: PROFILE_ROUTE.userById,
  getProfile: PROFILE_ROUTE.profile,
  getSubscriptionPlans: PROFILE_ROUTE.profile + "plans",
  getProgress: PROFILE_ROUTE.profile + "progress",
};
export const PUT_ROUTES = {
  progress: PROFILE_ROUTE.profile + "profile-progress",
  companyDetails: PROFILE_ROUTE.profile + "company-details",
  digitalInformation: PROFILE_ROUTE.profile + "digital-informations",
  companyLocation: PROFILE_ROUTE.profile + "company-locations",
  industriesDetails: PROFILE_ROUTE.profile + "industries-details",
  legalDocuments: PROFILE_ROUTE.profile + "legal-documents",
};
