import { FileWithPath } from "react-dropzone";
import axiosInstance from "../../services/axiosInstance";
import {
  CompanyDetailsCRUDType,
  CompanyDetailsFormType,
  CompanyLegalDocumentsFormType,
  CompanyLegalDocumentsRequestType,
  CompanyLocationsRequestType,
  CompanyLocationsFormType,
  DigitalInformationCRUDType,
  IndustryDetailsFormTypes,
  IndustryDetailsRequestTypes,
  ProfileDetailsResponseType,
  ProfileProgressType,
  MerchantUserDataType,
} from "../../types/merchantProfileTypes";
import { GET_ROUTES, PROFILE_ROUTE, PUT_ROUTES } from "./routes";
import { uploadDocument } from "../media/requests";

export const getProfileById = async (id: string) =>
  await axiosInstance.get<ProfileDetailsResponseType>(
    `${GET_ROUTES.getProfileById}${id}`
  );

export const getProfileData = async () => {
  return await axiosInstance.get<ProfileDetailsResponseType>(
    `${GET_ROUTES.getProfile}`
  );
};

export const getCompletionProgress = async () => {
  return await axiosInstance.get<ProfileProgressType>(
    `${GET_ROUTES.getProgress}`
  );
};

export const getUserById = async (userId: string) => {
  return axiosInstance.get<MerchantUserDataType>(
    `${PROFILE_ROUTE.userById}${userId}`
  );
};

//* GET Requests end

//* PUT Requests start
export const updateCompanyDetails = async (payload: CompanyDetailsFormType) => {
  const logo = payload.logo
    ? (payload.logo?.fileWithPath as FileWithPath)
    : null;
  let responseMessage;
  if (logo) {
    responseMessage = await uploadDocument([logo]);
  }
  const data: CompanyDetailsCRUDType = {
    ...payload,
    logo: responseMessage?.message[0].fileId ?? payload.logo?.imageId ?? "",
  };
  return await axiosInstance.put(`${PUT_ROUTES.companyDetails}`, data);
};

export const updateDigitalInformation = async (
  payload: DigitalInformationCRUDType
) => {
  return await axiosInstance.put<DigitalInformationCRUDType>(
    `${PUT_ROUTES.digitalInformation}`,
    payload
  );
};

export const updateCompanyLocation = async (
  payload: CompanyLocationsFormType
) => {
  const data: CompanyLocationsRequestType = {
    ...payload,
    city: payload.city.id,
    country: payload.country.id,
  };
  return await axiosInstance.put(`${PUT_ROUTES.companyLocation}`, data);
};

export const updateIndustryDetails = async (
  payload: IndustryDetailsFormTypes
) => {
  const industriesPayload: IndustryDetailsRequestTypes = {
    industries: payload.industries.map((industry) => industry.id),
  };
  return await axiosInstance.put(
    `${PUT_ROUTES.industriesDetails}`,
    industriesPayload
  );
};

export const updateLegalDocuments = async (
  payload: CompanyLegalDocumentsFormType
) => {
  const crDocument = payload.crDocument?.fileWithPath
    ? (payload.crDocument?.fileWithPath as FileWithPath)
    : null;
  const licenseDocument = payload.licenseDocument?.fileWithPath
    ? (payload.licenseDocument?.fileWithPath as FileWithPath)
    : null;
  const documents = [];
  if (crDocument) {
    documents.push(crDocument);
  }
  if (licenseDocument) {
    documents.push(licenseDocument);
  }
  let responseMessage;
  if (documents.length > 0) {
    responseMessage = await uploadDocument(documents);
  }
  const data: CompanyLegalDocumentsRequestType = {
    ...payload,
    crNumber: payload.crNumber.toString(),
    crDocument:
      responseMessage && crDocument
        ? responseMessage?.message[0].fileId
        : payload.crDocument?.imageId ?? "",
    licenseDocument:
      responseMessage && documents.length > 1
        ? responseMessage.message[1].fileId
        : responseMessage && licenseDocument
        ? responseMessage.message[0].fileId
        : payload.licenseDocument?.imageId ?? "",
  };
  return await axiosInstance.put(`${PUT_ROUTES.legalDocuments}`, data);
};

export const updateCompletionProgress = async (payload: {
  profileProgress: number;
}) => {
  return await axiosInstance.put(`${PUT_ROUTES.progress}`, payload);
};
//* PUT Requests end
