import axiosInstance from "../../services/axiosInstance";
import { GET_ROUTES } from "./routes";

export const handshake = async (token: string) => {
  return await axiosInstance.get<any>(`${GET_ROUTES.handshake}`, {
    params: {
      token
    }
  });
};
