import { MERCHANT_DEALS_ROUTES } from "../../../../api/merchantDeals/routes";
import { PARENT_ROUTES } from "../../../../parentRoutes";
import { RoleEnum } from "../../../../types/generalTypes";
import {
  NotificationActions,
  NotificationsListingResponseType,
} from "../../../../types/notificationTypes";
import { DEALMAKER_ROUTES } from "../../../dealMaker/routes";
import { MERCHANT_ROUTES } from "../../../merchant/routes";

export const getRouteForAction = ({
  action,
  id,
  role,
}: {
  action: NotificationActions;
  role: RoleEnum;
  id: string | undefined;
}) => {
  const routeId = id ? id : undefined;
  const routeMap = {
    [RoleEnum.MERCHANT +
    NotificationActions.CreatedOffering]: `${PARENT_ROUTES.merchant}/${MERCHANT_ROUTES.offering}${MERCHANT_ROUTES.view}${routeId}`,
    [RoleEnum.SELLER +
    NotificationActions.CreatedOffering]: `${PARENT_ROUTES.deal_maker}/${DEALMAKER_ROUTES.offering}${DEALMAKER_ROUTES.view}${routeId}`,
    [RoleEnum.MERCHANT +
    NotificationActions.InviteJob]: `${PARENT_ROUTES.deal_maker}/${MERCHANT_ROUTES.job}${DEALMAKER_ROUTES.view}${routeId}`,
    [RoleEnum.SELLER +
    NotificationActions.InviteJob]: `${PARENT_ROUTES.deal_maker}/${DEALMAKER_ROUTES.job}${DEALMAKER_ROUTES.view}${routeId}?invited=true`,
    [RoleEnum.MERCHANT +
    NotificationActions.ApplyJob]: `${PARENT_ROUTES.merchant}/${MERCHANT_ROUTES.applications}${routeId}?selected=applied`,
    [RoleEnum.MERCHANT +
    NotificationActions.Interview]: `${PARENT_ROUTES.merchant}/${MERCHANT_ROUTES.applications}${routeId}?selected=interview`,
    [RoleEnum.SELLER +
    NotificationActions.Interview]: `${PARENT_ROUTES.deal_maker}?selected=interview`,
    [RoleEnum.SELLER +
    NotificationActions.Contract]: `${PARENT_ROUTES.deal_maker}?selected=Sent`,
    [RoleEnum.MERCHANT +
    NotificationActions.Contract]: `${PARENT_ROUTES.merchant}/${MERCHANT_ROUTES.applications}${routeId}?selected=contracted`,
    [RoleEnum.SELLER +
      NotificationActions.Reject]: `${PARENT_ROUTES.deal_maker}?selected=rejected`,
    [RoleEnum.SELLER +
      NotificationActions.Deal]: `${PARENT_ROUTES.deal_maker}/${DEALMAKER_ROUTES.deals}${routeId}`,
    [RoleEnum.MERCHANT +
      NotificationActions.Deal]: `${PARENT_ROUTES.merchant}/${MERCHANT_DEALS_ROUTES.deal}${routeId}`,
    [RoleEnum.MERCHANT +
      NotificationActions.Review]: `${PARENT_ROUTES.merchant}/${MERCHANT_ROUTES.profile}?selected=3`,
  };
  return routeMap[role + action];
};

export const getUnStarredNotifications = (
  notificationsData: NotificationsListingResponseType
) => notificationsData.filter((notification) => !notification.isStarred);

export const getStarredNotifications = (
  notificationsData: NotificationsListingResponseType
) => {
  return notificationsData.filter((notification) => {
    return Boolean(notification !== undefined && notification.isStarred);
  });
};

export const getReadNotifications = (
  notificationsData: NotificationsListingResponseType
) =>
  notificationsData.filter((notification) =>
    Boolean(notification !== undefined && !notification.isRead)
  );

export const getAllHeight = (length: number) => {
  const height = length === 0 ? 150 : length < 6 ? length * 150 : undefined;
  return height ? height + "px" : undefined;
};

export const getStarredHeight = (length: number) => {
  const height = length === 0 ? 150 : length < 6 ? length * 120 : undefined;
  return height ? height + "px" : undefined;
};
