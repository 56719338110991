import { FileWithPath } from "react-dropzone";
import axiosInstance from "../../services/axiosInstance";
import { MediaType, MediaTypeResponse } from "../../types/generalTypes";
import { GET_ROUTES } from "./routes";
import { MediaMetaDataResponseType } from "../../types/mediaTypes";
import { getFileNameAndExtension } from "../../utils/helpers";
import { MAX_FILES_UPLOAD_COUNT } from "../../utils/constants";

export const uploadDocument = async (files: FileWithPath[]) => {
  let allMedia: MediaType[] = [];
  for (let k = 0; k < Math.ceil(files.length / MAX_FILES_UPLOAD_COUNT); k++) {
    const formData = new FormData();
    for (
      let i = k * MAX_FILES_UPLOAD_COUNT;
      i < k * MAX_FILES_UPLOAD_COUNT + MAX_FILES_UPLOAD_COUNT;
      i++
    ) {
      if (i === files.length) break;
      formData.append("files", files[i]);
    }
    const { data } = await axiosInstance.post<MediaTypeResponse>(
      "/media",
      formData
    );
    allMedia = [...allMedia, ...data.message];
  }
  return {
    message: allMedia,
    isSuccessful: true,
    documentIds: allMedia.map((media) => media.fileId),
  };
};

export const getDocumentMetaData = async (id: string) => {
  return await axiosInstance.get<MediaMetaDataResponseType>(
    `${GET_ROUTES.getMetaData}${id}`
  );
};
export const getDocumentById = async (id: string) => {
  return await axiosInstance.get<string>(`${GET_ROUTES.getMedia}${id}`, {});
};

export const downloadDocumentById = async (id: string, filename: string) => {
  const { extension, name } = getFileNameAndExtension(filename);
  const response = await axiosInstance.get<any>(`${GET_ROUTES.getMedia}${id}`, {
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));

  // Create an anchor element and click it to trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = name + "." + extension; // You can specify the filename here
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};
