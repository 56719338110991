import { useFormikContext } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  SignupAttributesType,
  SignupFieldNames,
  SignupFormType,
} from "../../../../types/signupTypes";
import { IoArrowBackSharp } from "../../../../utils/icons";
import CancelConfirmationModal from "../CancelConfirmationModal";
import CustomButton from "../CustomButton";
import DetectOutsideClickWrapper from "../DetectOutsideClickWrapper";
import DocumentUploader from "../DocumentUploader";
import DropdownInputField from "../DropdownInputField";
import { CRNumberField, NumberField, StringField } from "../FormFields";
import HelperText from "../HelperText";
import ModalParent from "../ModalParent";

type PersonalInfoProps = {
  fieldNames: SignupFieldNames[];
  setSelectedSection: Dispatch<SetStateAction<number>>;
  selectedSection: number;
  setSkip: Dispatch<SetStateAction<boolean>>;
  skip: boolean;
};
const CompanyInfo = ({
  setSelectedSection,
  fieldNames,
  selectedSection,
  setSkip,
  skip,
}: PersonalInfoProps) => {
  const { values, touched, validateField, isSubmitting, handleSubmit } =
    useFormikContext<SignupFormType>();
  const [open, setOpen] = useState(false);
  const isOptional = () => {
    if (skip) {
      return true;
    } else {
      return fieldNames.every(
        (name) =>
          !Boolean(values?.attributes?.[name as keyof SignupAttributesType])
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedSection]);
  useEffect(() => {
    fieldNames.forEach((name) => {
      if (touched.attributes) {
        if (
          Boolean(touched?.attributes?.[name as keyof SignupAttributesType])
        ) {
          validateField(name);
        }
      }
    });
  }, [values.attributes, fieldNames, touched.attributes, validateField]);

  const handleSkip = () => {
    let skipCheck = false;
    fieldNames.forEach((name) => {
      if (values.attributes) {
        if (values.attributes[name as keyof SignupAttributesType]) {
          skipCheck = true;
        }
      }
    });
    setSkip(true);
    if (skipCheck) setOpen(true);
    else handleSubmit();
  };
  return (
    <>
      <div className="flex pt-8 justify-between items-center">
        <div onClick={() => setSelectedSection(1)} className="w-1/5 px-2">
          <IoArrowBackSharp className="ws__icon cursor-pointer" />
        </div>

        <p className="flex-[2] text-xl md:text-3xl   ">
          Add your Company Information
        </p>
        <span
          onClick={() => handleSkip()}
          className="text-custom-green underline font-semibold cursor-pointer   w-1/5"
        >{`Skip`}</span>
      </div>
      <div className="relative flex flex-col items-center overflow-y-auto overflow-x-hidden px-10 pb-10">
        <div className="flex flex-col w-full gap-y-4 mt-5 mb-5 max-md:my-5">
          <StringField
            isOptional={isOptional()}
            fieldName={SignupFieldNames.attributescompanyLegalName}
            fullWidth
            placeholder="Company Legal Name"
            label="Company legal Name"
            marginBottom="mb-2"
            marginTop="mt-0"
          />
          <DropdownInputField
            label="Company Size"
            fieldName={SignupFieldNames.attributescompanySize}
            fullWidth
            isOptional={isOptional()}
            options={[
              { name: "Select Company Size", id: "" },
              { name: "[0-50]", id: "[0-50]" },
              { name: "[50-100]", id: "[50-100]" },
              { name: "[100-200]", id: "[100-200]" },
              { name: "[300+]", id: "[300+]" },
            ]}
            placeholder="Company Size"
          />
          <CRNumberField
            isOptional={isOptional()}
            marginTop="mt-2"
            marginBottom="mb-2"
            fieldName={SignupFieldNames.attributescrNumber}
            fullWidth
            placeholder="C.R Number"
            label="C.R Number"
          />
          <div className="flex gap-x-3 gap-y-4 max-md:flex-col mb-3">
            <div className="flex-[5]">
              <NumberField
                isOptional={isOptional()}
                fieldName={SignupFieldNames.attributesinvestedCapital}
                fullWidth
                placeholder="Company official invested capital"
                label="Company official invested capital"
              />
            </div>
            <div className="flex-1">
              <DropdownInputField
                isOptional={isOptional()}
                options={[
                  { name: "Select", id: "" },
                  { name: "SAR", id: "SAR" },
                ]}
                label="Select Unit"
                fieldName={SignupFieldNames.attributesinvestedCapitalUnit}
                fullWidth
                placeholder="SAR"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start w-full  mb-2">
          <DocumentUploader
            fieldName={SignupFieldNames.attributescrDocument}
            title="Attach C.R documents"
            uploaderFrom="PROFILE"
          />
          {touched.attributes?.["crDocument"] &&
            values.attributes.crDocument === null &&
            !isOptional() && (
              <HelperText
                mt="mt-0"
                helperText={"CR Document cannot be empty"}
              />
            )}
        </div>
        <div className="flex flex-col items-start w-full">
          <DocumentUploader
            fieldName={SignupFieldNames.attributeslicenseDocument}
            title="Attach company license documents"
            uploaderFrom="PROFILE"
          />
          {touched.attributes?.["licenseDocument"] &&
            values.attributes.licenseDocument === null &&
            !isOptional() && (
              <HelperText
                mt="mt-0"
                helperText={"License Documents cannot be empty"}
              />
            )}
        </div>
        <div className="flex w-full justify-center my-2">
          <CustomButton
            type="submit"
            text={isSubmitting ? "Loading..." : "Save changes"}
          />
        </div>
      </div>
      {open && (
        <DetectOutsideClickWrapper onClick={() => setOpen(false)}>
          <ModalParent
            children={
              <CancelConfirmationModal
                text="Are you sure you want to skip?"
                cancel={() => setOpen(false)}
                proceed={() => handleSubmit()}
                proceedButtonText={isSubmitting ? "Loading..." : "Confirm"}
              />
            }
          />
        </DetectOutsideClickWrapper>
      )}
    </>
  );
};

export default CompanyInfo;
