import { useFormikContext } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PARENT_ROUTES } from "../../../../parentRoutes";
import { RoleEnum } from "../../../../types/generalTypes";
import {
  SignupFieldNames,
  SignupRequestType,
} from "../../../../types/signupTypes";
import { IoArrowBackSharp } from "../../../../utils/icons";
import CustomButton from "../CustomButton";
import {
  ConfirmPasswordField,
  CustomCheckboxField,
  EmailField,
  PasswordField,
  StringField,
} from "../FormFields";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type PersonalInfoProps = {
  fieldNames: SignupFieldNames[];
  setSelectedSection: Dispatch<SetStateAction<number>>;
  type: string;
  isPending: boolean;
  selectedSection: number;
  checkEmailExists: UseMutateAsyncFunction<
    AxiosResponse<
      {
        emailExist: boolean;
      },
      any
    >,
    Error,
    string,
    unknown
  >;
  email?: string;
  checkError: boolean;
  setCheckError: Dispatch<SetStateAction<boolean>>;
};
const PersonalInfo = ({
  setSelectedSection,
  fieldNames,
  type,
  isPending,
  checkEmailExists,
  selectedSection,
  email,
  checkError,
  setCheckError,
}: PersonalInfoProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedSection]);
  const {
    setFieldTouched,
    validateField,
    values,
    setErrors,
    errors,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<SignupRequestType>();
  const onCheckValidation = (emailCheck?: boolean) => {
    if (emailCheck) {
      validateField("email");
    } else {
      if (values.terms === false) {
        setCheckError(true);
      } else {
        setCheckError(false);
      }
      fieldNames.forEach((name) => setFieldTouched(name, true));
      setFieldTouched("confirmPassword", true);
      let errorCount = 0;
      validateField("confirmPassword").then((error) => {
        if (error) {
          errorCount++;
        }
      });
      fieldNames.forEach((name, index) =>
        validateField(name).then((error) => {
          if (error) {
            errorCount++;
          }
          if (
            index === fieldNames.length - 1 &&
            errorCount === 0 &&
            !emailCheck &&
            values.terms === true
          ) {
            setSelectedSection(2);
          }
        })
      );
    }
  };
  const checkEmail = async (checkEmail: boolean) => {
    setFieldTouched("email", true);
    if (values.email === "") {
      onCheckValidation(checkEmail);
      return;
    }
    try {
      const emailExistsData = await checkEmailExists(values.email);

      if (!emailExistsData?.data.emailExist) {
        if (type === RoleEnum.MERCHANT) onCheckValidation(checkEmail);
      } else {
        setErrors({ ...errors, email: "Email already exists" });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (email) {
      setFieldValue("email", email);
    }
  }, [email, setFieldValue]);
  return (
    <>
      <div
        onClick={() => setSelectedSection(0)}
        className="flex pt-8 justify-between"
      >
        <div className="px-2 w-1/3">
          <IoArrowBackSharp className="ws__icon cursor-pointer " />
        </div>

        <div className="flex justify-center items-center w-full">
          <p className="flex-[2] text-xl md:text-3xl  ">
            Signup and start {type === RoleEnum.MERCHANT ? "hiring" : "selling"}
          </p>
        </div>
        <div className="w-1/3" />
      </div>
      <div className="overflow-x-hidden flex flex-col items-center px-10 pb-10 ">
        <div className=" flex flex-col w-full gap-x-10 gap-y-4 mt-6 mb-5 max-md:my-5">
          <div className="flex gap-4">
            <StringField fullWidth label="First name" fieldName="firstName" />
            <StringField fullWidth label="Last name" fieldName="lastName" />
          </div>

          <div className="flex flex-col items-start">
            <EmailField
              suffix={
                isPending ? (
                  <svg
                    aria-hidden="true"
                    className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : undefined
              }
              fullWidth
              label="Email"
              fieldName={"email"}
              defaultValue={email}
              disabled={type === RoleEnum.MERCHANT_REPRESENTATIVE}
              onBlur={() => checkEmail(true)}
            />
          </div>
          {type === RoleEnum.MERCHANT && (
            <StringField
              fullWidth
              label="Company Name"
              fieldName={"companyName"}
            />
          )}
          <PasswordField fullWidth label="Password" fieldName={"password"} />
          <ConfirmPasswordField
            fullWidth
            passwordFieldValue={values.password}
            label="Confirm Password"
            fieldName={"confirmPassword"}
          />
        </div>
        <div className=" flex flex-col gap-4 w-full">
          <CustomCheckboxField
            className=" "
            size="small"
            fieldName={"terms"}
            checkboxText={"Yes, I understand and agree to the "}
            linkText="Wesell Terms of Service"
            link={() => navigate(PARENT_ROUTES.commingSoon)}
            isChecked={values.terms}
            errorText={checkError ? "Please accept terms and conditions" : ""}
            onChange={(isChecked) => {
              isChecked ? setCheckError(false) : setCheckError(true);
            }}
          />
        </div>
        <div className="mt-8">
          {type === RoleEnum.MERCHANT ? (
            <CustomButton
              onClick={() => checkEmail(false)}
              text={"Continue"}
              fontFamily=" "
              fontSize="large"
              variant="primary"
            />
          ) : (
            <CustomButton
              type="submit"
              text={isSubmitting ? "Loading..." : "Create my account"}
            />
          )}

          <div className="flex flex-wrap justify-center mt-5 mb-8">
            <p className="text-grayShades-customGray text-center font-normal   text-base px-1">
              {`Already have an account? `}
              <CustomButton
                text=" Sign in"
                variant="text"
                size="medium"
                fontSize="medium"
                noHover
                onClick={() => navigate(PARENT_ROUTES.login)}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
