import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetNotifications,
  useMarkAllAsRead,
} from "../../../../api/notifications/reactQueryHooks";
import { RootState } from "../../../../redux/store";
import { setToast } from "../../../../redux/toastSlice";
import { connectSocket } from "../../../../services/socket";
import {
  NotificationResponseType,
  NotificationsListingResponseType,
  SocketEvents,
} from "../../../../types/notificationTypes";
import { hasScrolledtoEnd } from "../../../../utils/helpers";
import CustomButton from "../CustomButton";
import DetectOutsideClickWrapper from "../DetectOutsideClickWrapper";
import TabHeader from "../TabHeader";
import { TabParent } from "../TabParent";
import {
  getAllHeight,
  getStarredHeight,
  getStarredNotifications,
  getReadNotifications as getUnReadNotifications,
} from "./functions";
import { NotificationIcon } from "./NotificationIcon";
import NotificationsListing from "./NotificationsListing";

const NotificationBox = () => {
  const { user, language } = useSelector(
    (state: RootState) => state.centeralizedStateData.user
  );
  // const socket = connectSocket(user?.userId);

  const [activeNotification, setActiveNotification] = useState<number>(0);
  const [allNotifications, setAllNotifications] =
    useState<NotificationsListingResponseType>([]);
  const [starredNotifications, setStarredNotifications] =
    useState<NotificationsListingResponseType>([]);
  const [isOpen, setOpen] = useState(false);
  const {
    fetchNextPage,
    data: notificationsPagesData,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    isFetching,
  } = useGetNotifications(1);

  const notificationsData = useMemo(() => {
    return notificationsPagesData
      ? notificationsPagesData.pages.flatMap((item) => item.rows)
      : [];
  }, [notificationsPagesData]);

  useEffect(() => {
    if (notificationsData && notificationsData.length > 0) {
      setAllNotifications(notificationsData);

      const starredNotifications = getStarredNotifications(notificationsData);
      setStarredNotifications(starredNotifications);
    }
  }, [notificationsData]);
  useEffect(() => {
    const socket = connectSocket(user?.userId);
    socket.on(
      SocketEvents.notification,
      (latestNotification: NotificationResponseType) => {
        const starred =
          starredNotifications.length > 0
            ? getStarredNotifications([
                latestNotification,
                ...starredNotifications,
              ])
            : getStarredNotifications([latestNotification]);
        setStarredNotifications(starred);
        if (allNotifications.length > 0) {
          setAllNotifications([latestNotification, ...allNotifications]);
        } else {
          setAllNotifications([latestNotification]);
        }
        refetch();
      }
    );

    return () => {
      socket.disconnect();
    };
  }, [user?.userId, allNotifications, refetch, starredNotifications]);
  const {
    mutate: markAllAsRead,
    error: markAllAsReadError,
    isError: isMarkAllAsReadError,
  } = useMarkAllAsRead();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMarkAllAsReadError) {
      dispatch(
        setToast({
          text:
            (markAllAsReadError as AxiosError).message ??
            "Something went wrong",
          variant: "error",
        })
      );
    }
  }, [markAllAsReadError, isMarkAllAsReadError, dispatch]);
  const unReadNotificationsLength =
    getUnReadNotifications(allNotifications).length;

  return (
    <DetectOutsideClickWrapper onClick={() => setOpen(false)}>
      <div className="relative flex items-center mx-4">
        <NotificationIcon
          notificationLength={unReadNotificationsLength}
          isOpen={isOpen}
          setOpen={setOpen}
        />
        <div
          style={{
            height:
              activeNotification === 0
                ? getAllHeight(allNotifications.length)
                : getStarredHeight(starredNotifications.length),
          }}
          className={`absolute overflow-y-auto shadow-datagrid ${
            isOpen ? "scale-100 origin-top-right" : "scale-0"
          } ${allNotifications.length > 6 && "h-[calc(100vh-200px)]"} ${
            allNotifications.length === 6 && "h-[calc(100vh-300px)]"
          } transition-all flex flex-col top-[55px] rounded-lg ${
            language === "en" ? "right-0" : "left-0"
          } w-80 bg-white px-2 z-[10000000]`}
        >
          <div className="flex justify-center">
            <TabHeader
              headerText={"All"}
              isSelected={activeNotification === 0}
              onClick={() => setActiveNotification(0)}
            />
            <TabHeader
              headerText={`Starred`}
              chipText={starredNotifications.length.toString()}
              isSelected={activeNotification === 1}
              onClick={() => setActiveNotification(1)}
            />
          </div>
          <div
            onScroll={(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
              if (hasScrolledtoEnd(event, hasNextPage && !isFetching)) {
                fetchNextPage();
              }
            }}
            className="flex h-full w-full mb-2 overflow-x-hidden overflow-y-auto"
          >
            <TabParent
              fullHeight
              index={0}
              noPadding
              selectedSection={activeNotification}
            >
              <>
                <div className="w-full text-end mb-2">
                  <CustomButton
                    variant="text"
                    noHover
                    text={"Mark all as Read"}
                    fontSize="medium"
                    size="medium"
                    onClick={() => markAllAsRead()}
                  />
                </div>
                <NotificationsListing
                  notifications={
                    allNotifications.length > 0 ? allNotifications : []
                  }
                  setOpen={setOpen}
                />
                {isFetchingNextPage && <p>Loading...</p>}
              </>
            </TabParent>
            <TabParent
              index={1}
              fullHeight
              noPadding
              selectedSection={activeNotification}
            >
              <>
                <NotificationsListing
                  isStarred
                  notifications={
                    starredNotifications.length > 0 ? starredNotifications : []
                  }
                  setOpen={setOpen}
                />
                {isFetchingNextPage && <p>Loading...</p>}
              </>
            </TabParent>
          </div>
        </div>
      </div>
    </DetectOutsideClickWrapper>
  );
};

export default NotificationBox;
