import axiosInstance from "../../services/axiosInstance";
import { GET_ROUTES, PUT_ROUTES } from "./routes";
import { uploadDocument } from "../media/requests";
import {
  DigitalInformationCRUDType,
  GetDealMakerProfileResponseType,
  IndustryDetailsFormTypes,
  IndustryDetailsRequestTypes,
  UpdateBasicInfoFormType,
  UpdateBasicInfoRequestType,
  UpdateCertificateListFormType,
  UpdateEducationListFormType,
  UpdateEducationRequestType,
  UpdateLanguagesListFormType,
  UpdateLanguagesRequestAndFormType,
  UpdateLegalDocsFormType,
  UpdateLegalDocsRequestType,
  UpdateSkillsRequestAndFormType,
  UpdateWorkExperienceFormListType,
  UpdateWorkExperienceRequestType,
  WorkLocationsFormType,
  WorkLocationsRequestType,
} from "../../types/dealMakerProfileTypes";
import { MONTH_NAMES } from "../../utils/constants";
import { FileWithPath } from "react-dropzone";
import { MediaTypeResponse } from "../../types/generalTypes";

export const getProfileData = async (id?: string) => {
  const route = id
    ? `${GET_ROUTES.getProfileById}${id}`
    : GET_ROUTES.getProfile;
  return await axiosInstance.get<GetDealMakerProfileResponseType>(route);
};

//* GET Requests end

//* PUT Requests start

export const updateSkills = async (payload: UpdateSkillsRequestAndFormType) => {
  return await axiosInstance.put(`${PUT_ROUTES.skills}`, payload);
};

export const updateWorkExperience = async (
  workExperiencesPayload: UpdateWorkExperienceFormListType
) => {
  const data: UpdateWorkExperienceRequestType = {
    workExperiences: workExperiencesPayload.workExperiences.map(
      (workExperience) => {
        if (
          workExperience.endDateMonth.length === 0 ||
          workExperience.endDateYear.length === 0
        ) {
          return {
            companyName: workExperience.companyName,
            description: workExperience.description,
            title: workExperience.title,
            country: workExperience.country.id,
            city: workExperience.city.id,
            present: true,
            startDate: {
              month: MONTH_NAMES.indexOf(workExperience.startDateMonth) + 1,
              year: Number(workExperience.startDateYear),
            },
          };
        } else {
          return {
            companyName: workExperience.companyName,
            description: workExperience.description,
            title: workExperience.title,
            country: workExperience.country.id,
            city: workExperience.city.id,
            present: false,
            startDate: {
              month: MONTH_NAMES.indexOf(workExperience.startDateMonth) + 1,
              year: Number(workExperience.startDateYear),
            },
            endDate: {
              month: MONTH_NAMES.indexOf(workExperience.endDateMonth) + 1,
              year: Number(workExperience.endDateYear),
            },
          };
        }
      }
    ),
  };
  return await axiosInstance.put(`${PUT_ROUTES.workExperiences}`, data);
};

export const updateEducation = async (
  educationPayload: UpdateEducationListFormType,
  certificatesPayload: UpdateCertificateListFormType
) => {
  const data: UpdateEducationRequestType = {
    educations: educationPayload.educations,
    certificates: certificatesPayload.certificates,
  };
  return await axiosInstance.put(`${PUT_ROUTES.educations}`, data);
};

export const updateBasicInfo = async (payload: UpdateBasicInfoFormType) => {
  const avatar = payload.avatar?.fileWithPath
    ? payload.avatar?.fileWithPath
    : null;
  let avatarResponse;
  if (avatar) {
    avatarResponse = await uploadDocument([avatar]);
  }
  const data: UpdateBasicInfoRequestType = {
    description: payload.description,
    firstName: payload.firstName,
    lastName: payload.lastName,
    nationality: payload.nationality.id,
    phoneNo: payload.phoneNo,
    avatar: payload.avatar?.imageId ?? "",
  };
  if (avatarResponse) {
    data.avatar = avatarResponse.message[0].fileId;
  }
  return await axiosInstance.put(`${PUT_ROUTES.basicInfo}`, data);
};

export const updateWorkLocations = async (payload: WorkLocationsFormType) => {
  const data: WorkLocationsRequestType = {
    workLocations: payload.workLocations.map((workLocation) => {
      return {
        country: workLocation.country.id,
        cities: [workLocation.cities.id],
        district: workLocation.district,
      };
    }),
  };
  return await axiosInstance.put(`${PUT_ROUTES.workLocations}`, data);
};

export const updateDigitalInformation = async (
  payload: DigitalInformationCRUDType
) => {
  return await axiosInstance.put(`${PUT_ROUTES.digitalInformation}`, payload);
};

export const updateIndustryDetails = async (
  payload: IndustryDetailsFormTypes
) => {
  const industriesPayload: IndustryDetailsRequestTypes = {
    industries: payload.industries.map((industry) => industry.id),
  };
  return await axiosInstance.put(
    `${PUT_ROUTES.industriesDetails}`,
    industriesPayload
  );
};
export const updateLanguages = async (payload: UpdateLanguagesListFormType) => {
  const data: UpdateLanguagesRequestAndFormType = {
    languages: payload.languages.map((data) => {
      return {
        language: data.language.id,
        proficiencyLevel: data.proficiencyLevel,
      };
    }),
  };
  return await axiosInstance.put(`${PUT_ROUTES.languages}`, data);
};

export const updateLegalDocs = async (payload: UpdateLegalDocsFormType) => {
  const nationalIdDocument: FileWithPath[] = [];
  const nationalIdDocumentIndexes: number[] = [];
  const passportDocumentIndexes: number[] = [];
  const otherDocumentIndexes: number[] = [];
  payload.nationalIdDocument.forEach((doc, index) => {
    if (doc?.fileWithPath !== undefined) {
      nationalIdDocumentIndexes.push(index);
      nationalIdDocument.push(doc?.fileWithPath as FileWithPath);
    }
  });
  const passportDocument: FileWithPath[] = [];
  payload.passportDocument.forEach((doc, index) => {
    if (doc?.fileWithPath !== undefined) {
      passportDocumentIndexes.push(index);
      passportDocument.push(doc?.fileWithPath as FileWithPath);
    }
  });
  const otherDocument: FileWithPath[] = [];
  payload.otherDocument.forEach((doc, index) => {
    if (doc?.fileWithPath !== undefined) {
      otherDocumentIndexes.push(index);
      otherDocument.push(doc?.fileWithPath as FileWithPath);
    }
  });
  let nationalIdDocumentResponse: MediaTypeResponse | undefined;
  if (nationalIdDocument.length > 0) {
    nationalIdDocumentResponse = await uploadDocument(nationalIdDocument);
  }
  let passportDocumentResponse: MediaTypeResponse | undefined;
  if (passportDocument.length > 0) {
    passportDocumentResponse = await uploadDocument(passportDocument);
  }
  let otherDocumentResponse: MediaTypeResponse | undefined;
  if (otherDocument.length > 0) {
    otherDocumentResponse = await uploadDocument(otherDocument);
  }
  const data: UpdateLegalDocsRequestType = {
    nationalId: payload.nationalId,
    passportNumber: payload.passportNumber,
    nationalIdDocs: payload.nationalIdDocument.map((doc) => doc?.imageId ?? ""),
    passportDocs: payload.passportDocument.map((doc) => doc?.imageId ?? ""),
    otherDocs: payload.otherDocument.map((doc) => doc?.imageId ?? ""),
  };
  if (nationalIdDocumentResponse !== undefined) {
    nationalIdDocumentIndexes.forEach((serviceIndex, index) => {
      data.nationalIdDocs[serviceIndex] =
        nationalIdDocumentResponse!.message[index].fileId;
    });
  }

  if (passportDocumentResponse !== undefined) {
    passportDocumentIndexes.forEach((serviceIndex, index) => {
      data.passportDocs[serviceIndex] =
        passportDocumentResponse!.message[index].fileId;
    });
  }
  if (otherDocumentResponse !== undefined && data.otherDocs) {
    otherDocumentIndexes.forEach((serviceIndex, index) => {
      data.otherDocs[serviceIndex] =
        otherDocumentResponse!.message[index].fileId;
    });
  }
  return await axiosInstance.put(`${PUT_ROUTES.legalDocs}`, data);
};

//* PUT Requests end
