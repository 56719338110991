import { SuccessAndErrorModalType } from "../../../types/generalTypes";
import { ErrorIcon, SuccessIcon } from "../../../utils/svgIcons";
import CustomButton from "./CustomButton";
import Loader from "./Loader";

const ErrorAndSuccessModal = ({
  cancel,
  isError,
  isSuccess,
  loading,
  text,
  proceed,
  successButtonText,
  retry,
  subtext,
  retryButton,
  defaultText = true,
}: SuccessAndErrorModalType) => {
  return (
    <div className="w-fit h-fit relative p-6 py-4 px-20 flex flex-col bg-white rounded-2xl animate-dropdown duration-200">
      <div>
        {loading && (
          <div className="absolute">
            <Loader />
          </div>
        )}
        {isSuccess ? (
          <div className="bg-white rounded-lg h-full flex flex-col">
            <div className="h-full flex py-4 justify-end flex-col">
              <div className="  my-4 flex flex-col justify-center items-center">
                <div className="mb-6">
                  <SuccessIcon />
                </div>
                <p className="text-2xl font-semibold">{text}</p>
                {subtext && (
                  <p className="text-lg text-custom-green">{subtext}</p>
                )}
              </div>
              <div className="mt-3">
                <CustomButton
                  onClick={proceed}
                  text={successButtonText ? successButtonText : "Great!"}
                />
              </div>
            </div>
          </div>
        ) : (
          isError && (
            <div className="bg-white rounded-lg h-full flex flex-col">
              <div className="h-full flex py-4 justify-end flex-col">
                <div className="  my-4 flex flex-col justify-center items-center">
                  <div className="mb-6">
                    <ErrorIcon />
                  </div>
                  <div className="  my-4">
                    <p className="text-2xl font-semibold">{text}</p>
                    {defaultText && (
                      <p className="text-2xl font-medium">An Error Occured</p>
                    )}
                  </div>
                </div>
                <div className="mt-3 flex flex-col gap-3 items-center justify-center">
                  {(retryButton === undefined || retryButton === true) && (
                    <CustomButton
                      variant="error"
                      onClick={retry}
                      text="Retry"
                    />
                  )}
                  <CustomButton variant="text" onClick={cancel} text="Cancel" />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ErrorAndSuccessModal;
