import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { useGetProfileData } from "../../../api/merchantProfile/reactQueryHooks";
import profilePicture from "../../../assets/images/profilePicture.png";
import { RootState } from "../../../redux/store";
import { RoleEnum } from "../../../types/generalTypes";
import { capitalizeFirstLetter } from "../../../utils/helpers";
import { IoChevronDown } from "../../../utils/icons";
import CustomButton from "./CustomButton";
import CustomImage from "./CustomImage";

type MerchantHeaderImageProps = {
  isExpanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  stateUserName: string;
};
const MerchantHeaderImage = ({
  isExpanded,
  setExpanded,
  stateUserName,
}: MerchantHeaderImageProps) => {
  const { isLoggedIn, role, language } = useSelector(
    (state: RootState) => state.centeralizedStateData.user
  );
  const { data: profileData } = useGetProfileData({
    enabled: isLoggedIn && role === RoleEnum.MERCHANT,
  });
  const profile = profileData?.data;
  const username = capitalizeFirstLetter(
    profile?.firstName.split(" ")[0] ?? ""
  );
  return (
    <CustomButton
      text={username ?? stateUserName}
      variant="text"
      onClick={() => setExpanded(!isExpanded)}
      leftIcon={
        profile &&
        profile.attributes &&
        profile.attributes.companyDetails &&
        profile?.attributes.companyDetails.logo ? (
          <CustomImage
            variant="small"
            radius="rounded-full"
            imageId={profile.attributes.companyDetails.logo}
          />
        ) : (
          <img
            src={profilePicture}
            alt="profilepic"
            className={`w-10 h-10 ${language === "en" ? "mr-2" : "ml-2"}`}
          />
        )
      }
      noHover
      textVariant="gray"
      icon={
        <IoChevronDown className="text-grayShades-customGray group-hover:text-grayShades-datagrid ml-1" />
      }
    />
  );
};

export default MerchantHeaderImage;
