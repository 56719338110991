import { MarginRightType } from "../../../types/generalTypes";

type TabHeaderProps = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isSelected: boolean;
  headerText: string;
  chipText?: string | number;
  rightIcon?: JSX.Element;
  mr?: MarginRightType;
};
const TabHeader = ({
  onClick,
  isSelected,
  headerText,
  chipText,
  rightIcon,
  mr = "mr-5",
}: TabHeaderProps) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col w-fit ${mr} cursor-pointer`}
    >
      <div className="flex-1"></div>
      <div className="flex gap-x-2 w-max items-center">
        <p
          className={`flex-[3] py-2 ${
            isSelected ? "text-secondary-green" : " text-grayShades-customGray"
          }`}
        >
          {headerText}
        </p>
        {rightIcon && rightIcon}
        {chipText && (
          <p
            className={`${
              isSelected ? "bg-secondary-green" : " bg-grayShades-customGray"
            } rounded-[4px] px-1 text-white font-semibold text-base h-min`}
          >
            {chipText}
          </p>
        )}
      </div>
      <div
        className={`${
          isSelected ? "w-full" : "w-0"
        } transition-[width] h-[2px] bg-secondary-green`}
      ></div>
    </div>
  );
};

export default TabHeader;
