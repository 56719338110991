import axiosInstance from "../../services/axiosInstance";
import { POST_ROUTES } from "./routes";

export const sendResetEmail = (email: string) => {
  return axiosInstance.post<{
    id: string;
    message: string;
  }>(POST_ROUTES.resetPassword + email);
};

export const checkEmailExists = (email: string) => {
  return axiosInstance.post<{emailExist: boolean}>(POST_ROUTES.emailExists + email);
};

export const updatePassword = (token: string, password: string) => {
  return axiosInstance.put<{
    id: string;
    message: string;
  }>(POST_ROUTES.updatePassword, { password },{ params: {
    token
  }});
};
