import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  changeDealStatus,
  getAllMerchantDeals,
  getAllMerchantDealsForBuyers,
  getMerchantDealById,
} from "./requests";
import { GET_ROUTES, POST_ROUTES } from "./routes";
import { MerchantDealFilterType } from "../../types/merchantDealsTypes";

export const useGetInfiniteDealsListing = ({
  pageNumber,
  searchText,
  selectedSellerId,
  selectedJobId,
}: {
  pageNumber: number;
  searchText: string;
  selectedJobId: string;
  selectedSellerId: string;
}) => {
  return useInfiniteQuery({
    queryKey: [
      GET_ROUTES.deals,
      "infinite",
      pageNumber,
      searchText,
      selectedJobId,
      selectedSellerId,
    ],
    queryFn: ({ pageParam }) =>
      getAllMerchantDealsForBuyers({
        pageNumber: pageParam,
        searchText,
        selectedJobId,
        selectedSellerId,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.data.page + 1;
      const calc = Math.ceil(lastPage.data.total / lastPage.data.size);
      return nextPage <= calc && calc !== Infinity ? nextPage : undefined;
    },
  });
};

export const useGetMerchantDealsListing = ({
  pageNumber,
  searchText,
  enabled,
  filterBy,
}: {
  pageNumber: number;
  searchText: string;
  filterBy?: MerchantDealFilterType;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [GET_ROUTES.deals, pageNumber, searchText, filterBy],
    queryFn: () => getAllMerchantDeals({ pageNumber, searchText, filterBy }),
    enabled: enabled !== undefined ? enabled : true,
  });
};

export const useGetMerchantDealById = (id: string) => {
  return useQuery({
    queryKey: [GET_ROUTES.deals],
    queryFn: () => getMerchantDealById({ id }),
  });
};

export const useChangeDealStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [POST_ROUTES.changeStatus],
    mutationFn: ({
      dealId,
      status,
      amount,
    }: {
      dealId: string;
      status: "approval" | "complete" | "disapprove";
      amount?: number;
    }) => {
      return changeDealStatus({ dealId, status, amount });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ROUTES.deals],
      });
    },
  });
};
