import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useHandshake } from "../../../api/public/reactQueryHooks";
import { convertToGeneralError } from "../../../utils/helpers";
import DetectOutsideClickWrapper from "../components/DetectOutsideClickWrapper";
import ModalParent from "../components/ModalParent";
import ErrorAndSuccessModal from "../components/ErrorAndSuccessModal";
import { PARENT_ROUTES } from "../../../parentRoutes";

export default function Handshake() {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const {
    data: handshakeResponse,
    error,
    isSuccess,
    isLoading,
    isError,
    refetch,
  } = useHandshake(searchParam.get("token")!);
  const convedtedError = useMemo(() => convertToGeneralError(error), [error]);

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-64px)] text-custom-black relative bg-stripes bg-no-repeat bg-cover bg-bottom-right-lg max-md:bg-bottom lg:bg-bottom-right 2xl:bg-bottom-right xl:bg-bottom-right w-full">
      <div className="bg-custom-white p-12 rounded-lg shadow-smgreen w-full sm:w-96 xl:min-w-[310px] my-8  ">
        <p className="text-4xl text-center font-thin   italic mb-8 2xl:mb-12">
          {isLoading ? "Processing..." : ""}
          {(isSuccess || isError) && (
            <DetectOutsideClickWrapper onClick={() => {}}>
              <ModalParent
                children={
                  <ErrorAndSuccessModal
                    isError={isError ?? false}
                    isSuccess={isSuccess ?? false}
                    loading={false}
                    retry={() => {
                      refetch();
                    }}
                    retryButton={false}
                    successButtonText="Got it"
                    text={
                      isSuccess
                        ? handshakeResponse.data
                        : convedtedError?.response?.data.message ??
                          "Something went wrong"
                    }
                    proceed={() => {
                      window.close();
                      navigate(`${PARENT_ROUTES.login}`);
                    }}
                    cancel={() => {
                      window.close();
                    }}
                  />
                }
              />
            </DetectOutsideClickWrapper>
          )}
        </p>
      </div>
    </div>
  );
}
