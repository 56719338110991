import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useGetLanguage } from "../../../../utils/languageHelpers";
import { BellSvg } from "../../../../utils/svgIcons";

export const NotificationIcon = ({
  notificationLength,
  isOpen,
  setOpen,
}: {
  notificationLength: number;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const language = useGetLanguage();
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setTrigger(true);
    setTimeout(() => {
      setTrigger(false);
    }, 2000);
  }, [notificationLength]);

  return (
    <div className={`relative md:mt-2 ${language === "en" ? "mr-2" : "ml-2"}`}>
      {notificationLength > 0 && (
        <div
          className={`rounded-full flex justify-center items-center pt-[1px] absolute z-50 bg-error ${
            language === "en"
              ? notificationLength > 10
                ? "size-[26px] -top-3 -left-3"
                : "size-[22px] -top-3 -left-2"
              : notificationLength > 10
              ? "size-[26px] -top-3 -right-3"
              : "size-[22px] -top-3 -right-2"
          }`}
        >
          <p className={`text-sm text-white ${trigger && "animate-dropdown"}`}>
            {notificationLength > 10 ? "10+" : notificationLength}
          </p>
        </div>
      )}

      {isOpen ? (
        <BellSvg
          onClick={() => setOpen(false)}
          className={"fill-grayShades-borderGray size-9"}
        />
      ) : (
        <BellSvg
          onClick={() => setOpen(true)}
          className={
            "stroke-grayShades-borderLightGray fill-grayShades-borderLightGray size-9 hover:fill-grayShades-borderGray"
          }
        />
      )}
    </div>
  );
};
